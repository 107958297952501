<template>
  <div class="help-center-y2023" data-spm="14">
    <div class="help-center-container" style="margin-bottom: 20px; display: flex; align-items: center; justify-content: space-between">
      <section class="section-left">
        <!-- 我的账户 -->
        <div class="f-link-box f-link-left bwddd">
          <div class="f-link-h f-link-h3">{{ $t('footer.my.account') }}</div>
          <ul class="f-link-list">
            <template v-if="isLogin">
              <li>
                <a href="javascript:" rel="nofollow" @click="onLogout()">{{ $t('footer.my.logout') }}</a>
              </li>

              <li>
                <router-link :to="{ name: 'orderAll' }" v-if="user.type == 1">{{ $t('footer.my.order') }}</router-link>
              </li>

              <li>
                <router-link :to="{ name: 'storeOrderList' }" v-if="user.type == 2">{{ $t('footer.my.order') }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'wishlist' }" v-if="user.type == 1">{{ $t('footer.my.wish') }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'beseller' }">{{ $t('footer.my.join') }}</router-link>
              </li>
            </template>
            <template v-else>
              <li>
                <router-link :to="{ name: 'storeSignin' }">{{ $t('footer.my.order') }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'storeSignin' }">{{ $t('footer.my.wish') }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'storeSignin' }">{{ $t('footer.my.join') }}</router-link>
              </li>
            </template>
          </ul>
          <!-- 成为商家 -->
          <div class="f-link-h f-link-h3">{{ $t('footer.be.seller') }}</div>
          <ul class="f-link-list">
            <li>
              <router-link :to="{ name: 'beseller' }">
                <span class="_be_seller">{{ $t('footer.apply.now') }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- 关于我们 -->
        <div class="f-link-box f-link-left bwddd">
          <div class="f-link-h f-link-h3">{{ $t('footer.about.us') }}</div>
          <ul class="f-link-list">
            <li>
              <router-link :to="{ name: 'help_company', query: { key: 'qualification' } }">{{ $t('_footer._platform._qualification') }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'help_company', query: { key: 'business_model' } }">{{ $t('_abt._syms') }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'help_company', query: { key: 'liability' } }">{{ $t('_abt._qyzr') }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'help_company', query: { key: 'contact_us' } }">{{ $t('_abt._lxwm') }}</router-link>
            </li>
          </ul>
        </div>

        <div class="f-link-right">
          <div class="f-link-box bwddd">
            <div class="f-link-h f-link-h3">{{ $t('_footer._suom') }}</div>

            <ul class="f-link-list">
              <li>
                <router-link :to="{ name: 'help_docs', query: { key: 'terms_conditions' } }">{{ $t('footer.terms.conditions') }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'help_docs', query: { key: 'return_policy' } }">{{ $t('footer.return.policy') }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'help_docs', query: { key: 'support_policy' } }">{{ $t('footer.support.policy') }}</router-link>
              </li>
              <li style="display: flex; flex-direction: column">
                <router-link :to="{ name: 'help_docs', query: { key: 'privacy_policy' } }">{{ $t('footer.privacy.policy') }}</router-link>
              </li>
            </ul>
          </div>

          <div class="f-link-box bwddd">
            <div class="f-link-h f-link-h3">{{ $t('footer.contact') }}</div>
            <ul class="f-link-list">
              <li>
                <a href="javascript:" rel="nofollow">{{ $t('footer.gfemail') }}:<br> shopifyoutlets@shopifyoutlets.com</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="section-right" style="width: 320px">
        <div class="f-sns-box">
          <div>
            <div class="foot-sns-box">
              <div class="app-box">
                <div class="social-networks" style="width: 315px">
                  <img src="@/static/css5/logo2.png" alt="" style="margin-bottom: 10px; width: 315px" />
                  <div class="foot-sns-box" style="display: flex; gap: 10px">
                    <div class="a-download">
                      <img src="@/static/play.png" alt="" style="width: 100%; cursor: pointer" @click="onGoUrl('android')" />
                      <div class="app-download">
                        <img src="@/assets/app_android.png" alt="" />
                      </div>
                    </div>
                    <div class="a-download">
                      <img src="@/static/app.png" alt="" style="width: 100%; cursor: pointer" @click="onGoUrl('ios')" />
                      <div class="app-download">
                        <img src="@/assets/app_ios.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="jekks">
      <div class="jeksld">
        <div class="">
          <p style="font-size: 12px">{{ $t('_dk._yjwab') }}</p>
          <div class="eksje">{{ $t('_dk._yjwa') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: false,
      user: {}
    }
  },
  created() {
    this.tokenStatus()
  },
  methods: {
    onGoUrl(type) {
      console.log(type)
      if (type === 'android') {
        let url = 'https://wap.shopifyoutlets.com/#/pages/download/android'
        window.open(url, '_blank')
      } else if (type === 'ios') {
        let url = 'https://wap.shopifyoutlets.com/#/pages/download/iphone'
        window.open(url, '_blank')
      }
    },
    /**
     * 登录状态
     * 由topnav 内部调用父类触发
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.user = this.$common.getInfo()
    },
    onLogout() {
      this.$emit('onLogout', [])
    }
  }
}
</script>

<!-- <style scoped src="@/static/css5/index/footer.css"></style> -->
<style>
.jekks {
  padding: 10px;
  background-color: #000;
  color: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jeksld {
  width: 1600px;
}

.eksje {
  font-size: 13px;
}
</style>
<style scoped>
.site-main-box {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.container-box {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  width: 1200px;
  display: flex;
}

.site-box {
  float: left;
  padding: 15px 0;
  text-align: center;
  position: relative;
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.site-box:hover {
  background: #f4f4f4;
}

.site-box h3 {
  color: #000000;
  font-weight: 700;
  font-size: 15px;
}

.container-box:first-child {
  border-left: 1px solid #f4f4f4;
}

.site-box {
  border-right: 1px solid #f4f4f4;
}

.site-box .i-great {
  background: url('@/static/index/4.png') no-repeat 0 0;
  background-size: cover;
}

.site-box .icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._be_seller {
  display: inline-block;
  background-image: linear-gradient(94deg, var(--main-color1), var(--main-color2));
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  padding: 5px 15px;
}

.c_flex {
  display: flex;
}

.app-download {
  position: absolute;
  width: 170px;
  height: 170px;
  z-index: 10;
  bottom: 100%;
  border: 2px solid #ff5722;
  display: none;
}
.app-download img {
  width: 100%;
  height: 100%;
}
.a-download {
  flex: 1;
  position: relative;
}
.a-download:hover .app-download {
  display: block;
}

.xx-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-bottom {
  font-size: 12px;
  padding: 22px 20px;
  color: #6b6b6b;
}

.bwddd {
  width: 220px;
}
</style>
