export default {
  'nav.account': 'Personal Center',
  'nav.language': 'Vereinfachtes Chinesisch',
  'signup.success': 'Anmeldung erfolgreich',

  //Fehler
  'contact.empty': 'Kontaktinformationen dürfen nicht leer sein',
  'phone.country.empty': 'Die Telefonvorwahl darf nicht leer sein',
  'phone.empty': 'Telefon darf nicht leer sein',
  'email.valid': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',

  // Basis
  'base.success': 'Erfolg',
  'base.save': 'Speichern',
  'base.slide': 'Zum Bestätigen ziehen Sie bitte den Schieberegler',
  'base.send.code': 'Code senden',
  'base.send.time': '({time}) Code erneut senden',
  'base.send.success': 'Erfolgreich gesendet',
  'base.view.more': 'Mehr anzeigen',
  'Basis.Sprache': 'Sprache',
  'base.previous': 'Vorherige Seite',
  'base.next': 'Nächste Seite',
  'base.total.page': 'Gesamt {page} Seiten',
  'base.go.to.page': 'Gehe zu Seite',
  'base.go': 'Gehe zu',
  'base.followers': 'Follower',
  'base.follow': 'Folgen',
  'base.following': 'Folge',
  'base.visit.store': 'Shop durchsuchen',
  'base.contact': 'Kontaktieren Sie uns',
  'base.delivery': 'Geschätzte Lieferzeit: 1-7 Tage',
  'base.search': 'Suchen',
  'base.search.text': 'Schlüsselwörter für die Suche eingeben',
  'base.server': 'Online-Kundenservice',

  //Index
  'text.categories.title': 'Produktkategorie',
  'text.welcome': 'Willkommen zum Einkaufen!',
  'text.wishlist': 'Wunschliste',
  'text.account': 'Personal Center',
  'text.orders': 'Bestellungen',
  'text.messages': 'Nachricht',
  'text.suggest.title': 'Exklusives Angebot',
  'text.suggest.content': 'Nur für neue globale Premium-Mitglieder!',
  'text.super.deals': 'Top-Produkte zu unglaublichen Preisen.',

  //Anmeldung
  'signup.register': 'Anmeldung',
  'signup.signin': 'Anmelden',
  'signup.store.signin': 'Firmen-Login',
  'signup.sign.out': 'Abmelden',
  'signup.email': 'E-Mail-Adresse',
  'signup.password': 'Passwort',
  'signup.qr.password': 'Passwort bestätigen',
  'signup.confirm.password': 'Passwort bestätigen',
  'signup.forgot.password': 'Passwort vergessen',
  'registrierung.einladung': 'Einladungscode',
  'signup.char': 'Charakter',
  'signup.contains': 'Enthält Zahlen, Buchstaben oder Symbole',
  'signup.qr.contains': 'Die beiden Passwörter sind nicht konsistent',
  'signup.create.account': 'Konto erstellen',
  'signup.accept': 'Erstelle ein Konto, du stimmst zu',
  'signup.member.agreement': 'Mitgliedervereinbarung',
  'signup.and': 'und',
  'signup.privacy.policy': 'Datenschutzrichtlinie',
  'signup.email.code': 'E-Mail-Bestätigung',
  'signup.last.step': 'Letzter Schritt',
  'signup.send.email': 'Bitte geben Sie den 4-stelligen Code ein, der an {email} gesendet wurde',
  'signup.modify.email': 'E-Mail ändern',
  'signup.verify.email': 'Bestätigungs-E-Mail',
  'signup.have.store': 'Händlerkonto haben',
  'signup.goto.signin': 'Business-Login',
  'signup.no.store': 'Kein Händlerkonto',
  'signup.goto.store': 'Händler anmelden',
  'signup.next': 'Weiter',
  'signup.your.email': 'Ihre E-Mail',
  'signup.code.text': 'Bestätigungscode',
  'signup.submit.signup': 'Jetzt anmelden',
  'signup.smrz': 'Authentifizierung mit echtem Namen',
  'signup.derb': 'Zweiter Schritt',
  'signup.qsrxm': 'Bitte geben Sie den Namen des Dokuments ein',
  'signup.qsrhm': 'Bitte geben Sie die ID-Nummer ein',

  //vergessen
  'forgot.title': 'Passwort zurücksetzen',
  'forgot.btn.check': 'E-Mail bestätigen',
  'forgot.reset.now': 'Jetzt zurücksetzen',

  //speichern
  'store.info.open': '{y} Woche geöffnet',

  // Fusszeile
  'footer.great.value': 'Toller Wert',
  'footer.great.value.desc': 'Wir bieten konkurrenzfähige Preise für über 100 Millionen Artikel.',
  'footer.shopping': 'Globales Einkaufen',
  'footer.shopping.desc': 'Wir versenden in über 200 Länder und Regionen und unsere Website ist in 7 Sprachen verfügbar.',
  'footer.safe.payment': 'Sichere Zahlung',
  'footer.safe.payment.desc': 'Bezahlen Sie mit den beliebtesten und sichersten Zahlungsmethoden der Welt.',
  'footer.shop.with.confidence': 'Mit Vertrauen einkaufen',
  'footer.shop.with.confidence.desc': 'Unsere Käuferschutzrichtlinie deckt Ihren gesamten Kaufprozess ab.',
  'footer.help.center': 'Hilfezentrum',
  'footer.help.center.desc': 'Unterstützung rund um die Uhr, um ein reibungsloses Einkaufserlebnis zu schaffen.',
  'footer.terms.conditions': 'Allgemeine Geschäftsbedingungen',
  'footer.return.policy': 'Rückgaberecht',
  'footer.support.policy': 'Support-Richtlinie',
  'footer.privacy.policy': 'Datenschutzrichtlinie',
  'footer.be.seller': 'Verkäufer werden',
  'footer.apply.now': 'Jetzt bewerben',
  'footer.stay.connected': 'In Verbindung bleiben',

  'footer.about.us': 'Über uns',
  'footer.about.company': 'Firmenprofil',
  'footer.about.video': 'Videoeinführung',
  'footer.contact': 'Kontakt',

  'footer.my.account': 'Mein Konto',
  'footer.my.logout': 'Abmelden',
  'footer.my.order': 'Bestellhistorie',
  'footer.my.wish': 'Meine Wunschliste',
  'footer.my.join': 'Partnermitglied werden',
  'footer.email': 'E-Mail',
  'footer.gfemail': 'Offizielle E-Mail',
  'footer.fwemail': 'Service-E-Mail',
  'footer.address': 'Adresse',

  'apply.success': 'Bewerbung erfolgreich',
  'apply.success.desc': 'Erfolgreich beworben, zum Anmeldekonto gehen',

  // Kategorien
  'category.title': 'Verwandte Kategorie',
  'category.all': 'Alle Kategorien',

  //Detail
  'detail.store.home': 'Zuhause des Geschäfts',
  'detail.sale.items': 'Artikelliste',
  'detail.recommend': 'Empfohlen',
  'detail.orders': 'Verkäufe',
  'detail.quantity': 'Menge',
  'detail.pieces.available': 'Verfügbar',
  'detail.delivery': 'Lieferung',
  'detail.free.shipping': 'Kostenloser Versand',
  'detail.estimated.delivery': 'Voraussichtliche Lieferung',
  'detail.Tage': 'Tage',
  'detail.buy.now': 'Kaufen',
  'detail.add.to.cart': 'Zum Warenkorb hinzufügen',
  'detail.buyer.protection': 'Käuferschutz',
  'detail.money.guarantee': 'Geld-zurück-Garantie',
  'detail.rückerstattung.desc': 'Erhalten Sie eine volle Rückerstattung, wenn der Artikel nicht wie beschrieben ist oder nicht geliefert wurde',
  'detail.description': 'Produktbeschreibung',
  'detail.customer.reviews': 'Kundenrezensionen',
  'detail.spezifikationen': 'Produktspezifikationen',
  'detail.top.selling.products': 'Meistverkaufte Produkte',
  'detail.recommended.for.you': 'Für Sie empfohlen',
  'detail.verkauft': 'Verkäufe',
  'detail.receipt': 'Empfang bestätigen',
  'detail.receipt.title': 'Eingang der Bestellung bestätigen?',
  'detail.empfang.inhalt': 'Nach der Bestätigung ist der Transaktionsauftrag abgeschlossen',
  'detail.comment': 'Kommentar',

  //bestätigen
  'confirm.shipping.address': 'Empfängeradresse',
  'Änderung bestätigen': 'Ändern',
  'confirm.payment.methods': 'Zahlungsmethoden',
  'confirm.summary': 'Abrechnung',
  'confirm.total.item.costs': 'Artikelsumme',
  'Bestätigung Gesamt': 'Gesamt',
  'Kasse bestätigen': 'Zur Kasse',
  'confirm.place.order': 'Jetzt bestellen',
  'confirm.pay.now': 'Jetzt bezahlen',
  'confirm.order.desc': "Nachdem ich auf 'Bestellung aufgeben' geklickt habe, bestätige ich, dass ich gelesen und bestätigt habe",
  'confirm.order.policy': 'Alle Bedingungen und Richtlinien',
  'bestätige Zahlung': 'Global Shopping gewährleistet Ihre Informations- und Zahlungssicherheit',

  // Adresse
  'address.title': 'Empfängeradresse',
  'address.default': 'Standard',
  'Adresse bearbeiten': 'Bearbeiten',
  'address.delete': 'Löschen',
  'address.new': 'Neue Adresse erstellen',
  'address.contact': 'Kontakt',
  'address.address': 'Adresse',
  'address.phone': 'Telefonnummer',
  'address.set.default': 'Standard festlegen',
  'address.confirm': 'Bestätigen',
  'Adresse.Abbrechen': 'Abbrechen',
  'address.del.title': 'Adresse löschen bestätigen?',
  'address.del.content': 'Sind Sie sicher, dass Sie diese Lieferadresse löschen möchten?',

  'payment.method': 'Zahlungsmethode',

  // Einkaufswagen
  'shopping.title': 'Warenkorb',
  'shopping.back.buy': 'Zurück zum Sondereinkauf',
  'shopping.start.shopping': 'Einkaufen beginnen',

  // Mitglied
  'member.account': 'Personal Center',
  'member.overview': 'Übersicht',
  'member.orders': 'Bestellungen',
  'member.payment': 'Meine Brieftasche',
  'member.address': 'Lieferadresse',
  'member.wishlist': 'Wunschliste',
  'member.followlist': 'Followerliste',
  'member.message.center': 'Nachrichtenzentrum',
  'member.setting': 'Persönliche Einstellungen',
  'member.shop.info': 'Shop-Informationen',
  'member.shop.setting': 'Shop-Einstellungen',
  'member.order.notify': 'Neue Bestellung benachrichtigen',
  'member.order.tips': 'Durch Senden einer E-Mail an die E-Mail des Händlerkontos',

  // Member.Order
  'member.order.title': 'Meine Bestellung',
  'member.order.view.all': 'Alle anzeigen',
  'member.order.all': 'Alle',
  'member.order.unpaid': 'Unbezahlt',
  'member.order.paid': 'Bezahlt',
  'member.order.procurement': 'Ausstehende Beschaffung',
  'member.order.seller.paid': 'Gekauft',
  'member.order.processing': 'Ausstehend',
  'member.order.shipped': 'versendet',
  'member.order.return': 'Zurückkehren',
  'member.order.completed': 'Abgeschlossen',
  'member.order.refund': 'Erstattet',
  'member.order.all.time': 'Alle',
  'member.order.empty': 'Noch keine Bestellung',
  'member.order.date': 'Datum',
  'member.order.purchase.date': 'Kauf',
  'member.order.cpoy': 'Kopieren',
  'member.order.id': 'Bestellung',
  'member.order.detail': 'Bestelldetails',
  'member.order.logistics': 'Logistikinformationen',

  // mitglied.zahlung
  'member.payment.title': 'Meine Brieftasche',
  'member.wallet.balance': 'Wallet-Guthaben',
  'member.crypto.recharge': 'Aufladen',
  'member.crypto.withdrawal': 'Auszahlen',
  'member.crypto.bank': 'Bankkarte',
  'member.wallet.record': 'Coin-Einzahlungsaufzeichnung',
  'member.bankcard.record': 'Bankkartendatensatz',
  'member.withdrawal.record': 'Auszahlungsaufzeichnung',
  'member.income.record': 'Einkommensnachweis',
  'member.transaction.record': 'Transaktionsdatensatz',
  'member.wallet.freeze': 'Guthaben einfrieren',
  'member.wallet.profit': 'Geschätzte Einnahmen',

  //aufladen
  'recharge.currency': 'Währung',
  'recharge.protocol': 'Protokoll',
  'aufladen.qrcode': 'QR-Code',
  'recharge.address': 'Wallet-Adresse',
  'aufladen.kopieren': 'Adresse kopieren',
  'aufladen.ok.text': 'Bestätigung',
  'recharge.cancel.text': 'Stornieren',

  //Bank
  'bank.recharge.title': 'Bankkartenaufladung',
  'bank.title': 'Bankname',
  'bank.name': 'Name',
  'bank.account': 'Konto',
  'bank.routing': 'Routing',
  'bank.code': 'code',
  'bank.bankname': 'Bankname',
  'bank.bankaddress': 'Bankadresse',
  'bank.ok.text': 'Weiter',
  'bank.ok.prev': 'Vorheriger Schritt',
  'bank.submit': 'Jetzt einreichen',
  'bank.amount': 'Aufladebetrag',
  'bank.amount.desc': 'Bitte geben Sie den Aufladebetrag ein',
  'bank.type': 'Typ',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Belegnummer',
  'bank.receipt.number.desc': 'Bitte geben Sie die Belegnummer der Transaktion ein',
  'bank.credential.picture': 'Berechtigungsbild',
  'bank.credential.picture.desc': 'Bitte laden Sie das Ausweisbild hoch',
  'bank.remark': 'Bemerkung',
  'bank.upload': 'Bild hochladen',
  'bank.text.title': 'Notizen',
  'bank.text.t1': 'Benötigen Sie keine Bemerkungen für die Überweisung, lassen Sie es leer',
  'bank.text.t2': 'Wählen Sie für Haushalte in Hongkong statt China Hongkong aus',
  'bank.text.t3': 'Amerikanische Überweisung, europäische Überweisung an Wochentagen vor 15:00 Uhr, Überweisung ist während der Bankarbeitszeiten möglich!',
  'bank.text.t4': 'Die Zahlungszeit ist T+1, und die späteste Zahlung ist T+3 in Europa',
  'bank.text.t5': 'Informieren Sie die Plattform, bevor Sie Geld überweisen, um zu bestätigen, ob das Konto verfügbar ist.Wenn das Konto geschlossen wird, wird keine Entschädigung gezahlt.',
  'bank.text.t6': 'Einzahlung per Bankkarte. Kundendienstinformationen haben Vorrang.',

  //Bankliste
  'bank.list.title': 'Bankname',
  'bank.list.code': 'Bankleitzahl',
  'bank.list.amount': 'Überweisungsbetrag',
  'Bank.Listennummer': 'Quittungsnummer',
  'bank.list.img': 'Gutscheinbild',
  'bank.list.status': 'Status',
  'bank.list.time': 'Zeit',
  'bank.list.status1': 'Wird überprüft',
  'bank.list.status2': 'Bestanden',
  'bank.list.status3': 'Abgelehnt',

  // Rückzug
  'withdrawal.address.desc': 'Bitte geben Sie die Wallet-Adresse ein!',
  'withdrawal.number': 'Nummer',
  'withdrawal.real.number': 'tatsächliche Ankunft',
  'withdrawal.number.desc': 'Bitte geben Sie den Auszahlungsbetrag ein!',
  'withdrawal.btn.all': 'Alle',
  'withdrawal.balance': 'Guthaben',
  'withdrawal.commission': 'Provision',
  'withdrawal.actual.amount': 'Konto',
  'withdrawal.notice': 'Kündigung',
  'withdrawal.notice.text': 'Bitte bestätigen Sie vor der Übertragung, dass die Informationen zur Empfangsadresse korrekt sind.Sobald die Vermögenswerte übertragen wurden, können sie nicht mehr zurückgegeben werden.',
  'withdrawal.notice.content': '{name}({cp_name}) Provision: aktueller Marktwert {fee_rate} %/Transaktion, Mindeststandard: {fee_min} {name}/Transaktion',
  'withdrawal.submit': 'Einreichen',
  'withdrawal.choice': 'Wählen Sie eine Kryptowährung',
  'withdrawal.yzm': 'Bestätigungscode',
  'withdrawal.fs': 'Senden',
  'withdrawal.qsryzm': 'Bitte geben Sie den E-Mail-Bestätigungscode ein',

  // erhalten
  'recive.method': 'Protokoll',
  'recive.amount': 'Menge',
  'recive.address': 'Adresse',
  'recive.date': 'Uhrzeit',
  'recive.status': 'Status',
  'recive.create.at': 'Transaktionszeit',
  'recive.type': 'Typ',
  'recive.befor': 'Vor der Transaktion',
  'recive.balance': 'Guthaben',
  'recive.freeze': 'Einfrieren',
  'recive.review': 'Wird überprüft',
  'recive.success': 'Bestanden',
  'recive.reject': 'Abgelehnt',

  // werben
  'advertise.title': 'Werbezentrum',
  'advertise.shop.title': 'Shop-Promotion',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Abgelaufen',
  'advertise.shop.promotion': 'Promotion',
  'advertise.shop.expire.date': 'Ablaufdatum',
  'advertise.shop.renew': 'Anzeigenerneuerung',
  'advertise.shop.payable': 'Zu zahlender Betrag',
  'advertise.shop.explanation': 'Anzeigenerläuterung',
  'werben.shop.text': 'Beteiligen Sie sich an Plattform-Werbeaktionen, erhöhen Sie die Präsenz im Geschäft und fördern Sie Transaktionsbestellungen',
  'advertise.shop.paynow': 'Sofort verlängern',
  'advertise.shop.modal.title': 'Zahlung bestätigen',
  'advertise.shop.modal.desc': 'Zahlung der Aktionsgebühr bestätigt',
  'advertise.shop.modal.btn': 'Zahlung bestätigen',

  //Wunschzettel
  'wishlist.title': 'Wunschliste',
  'wishlist.delete': 'Löschen',
  'wishlist.orders': 'Verkäufe',

  //Liste folgen
  'followlist.title': 'Folgeliste',
  'followlist.delete': 'Löschen',
  'followlist.follow': 'Folgen',

  // speichern
  'store.dashboard': 'Dashboard',
  'store.products': 'Produkte',
  'store.products.list': 'Produktliste',
  'store.products.reviews': 'Produktbewertungen',
  'store.orders': 'Bestellungen',
  'store.wallet': 'Brieftasche',
  'store.message': 'Message Center',
  'store.setting': 'Einstellungen',
  'store.order.total.profit': 'Geschätzte Gesamtbetriebseinnahmen',

  //Armaturenbrett
  'dashboard.store.hour.views': 'Besuche in Echtzeit',
  'dashboard.store.today.views': 'Heutige Aufrufe',
  'dashboard.product.total': 'Gesamtprodukt',
  'dashboard.product.today': 'Heute hinzugefügt',
  'dashboard.order.total': 'Bestellsumme',
  'dashboard.sales.total': 'Geschätzter Umsatz',
  'dashboard.sales.real': 'Echte Verkäufe',
  'dashboard.sales.pay': 'Gesamtauszahlung',
  'dashboard.sales.profit': 'Gesamtgewinn',
  'dashboard.commission.total': 'Gesamtumsatz',
  'dashboard.commission.today': 'Heute Einnahmen',
  'dashboard.order.sales': 'Produktverkäufe',
  'dashboard.sales.list': 'Verkaufsrangliste',
  'dashboard.goods.cate.rate': 'Produktkategorieverhältnis',
  'dashboard.goods.wish': 'Like-Waren-Ranking',
  'dashboard.januar': 'Januar',
  'dashboard.februar': 'Februar',
  'dashboard.march': 'März',
  'dashboard.april': 'April',
  'dashboard.may': 'Mai',
  'dashboard.juni': 'Juni',
  'dashboard.juli': 'Juli',
  'dashboard.august': 'August',
  'dashboard.september': 'September',
  'dashboard.october': 'Oktober',
  'dashboard.november': 'November',
  'dashboard.december': 'Dezember',

  // Produkte
  'products.add.new': 'Neues Produkt hinzufügen',
  'products.add.from.warehouse': 'Produkte aus dem Lager hinzufügen',
  'products.delete': 'Löschen',
  'products.add': 'Hinzufügen',
  'products.table.img': 'Bild',
  'products.table.name': 'Name',
  'products.table.category': 'Kategorie',
  'products.table.wish': 'gefällt mir',
  'products.table.stock': 'Lager',
  'products.table.price': 'Preis',
  'products.table.profit': 'Gewinn',
  'products.table.action': 'Aktion',
  'products.search.category': 'Suche nach Kategorie',
  'products.back.product': 'Zurück zur Produktliste',
  'products.total': 'Produkte insgesamt',
  'products.ja': 'Ja',
  'products.no': 'Abbrechen',
  'products.batch.add': 'Stapel hinzufügen',
  'products.ask.add': 'Sind Sie sicher, dass Sie Produkte hinzufügen möchten?',
  'products.batch.delete': 'Stapel löschen',
  'products.ask.delete': 'Möchten Sie das Produkt wirklich löschen?',
  'products.top': 'Oben',
  'products.syzd': 'Startseitenanfang',
  'products.zdwz': 'Spitzenposition',
  'products.t1': 'Werbung',
  'products.t2': 'Bevorzugt',
  'products.t3': 'Boutique',
  'products.t4': 'keine',

  // Bewertungen
  'reviews.title': 'Produktbewertungen',
  'reviews.product.name': 'Produkt',
  'reviews.user.name': 'Benutzer',
  'reviews.star': 'Bewertung',
  'reviews.comment': 'Inhalt überprüfen',
  'reviews.sku': 'Produktspezifikationen',
  'reviews.imgs': 'Fotogalerie',
  'reviews.created': 'Datum',

  // speichern.bestellung
  'store.order.purchase': 'Jetzt kaufen',
  'store.order.purchase.desc': 'Sind Sie sicher, dass Sie für dieses Produkt bezahlen möchten?',
  'store.order.purchase.yes': 'sofort bezahlen',
  'store.order.purchase.no': 'Stornieren',
  'store.order.desc': 'Für den Kauf dieser Bestellung ist eine Zahlung erforderlich, und Sie erhalten Vorteile, nachdem die Bestellung abgeschlossen wurde.',
  'store.order.no': 'Bestellnummer',
  'store.order.number': 'Menge',
  'store.order.buyer': 'Käufer',
  'store.order.total': 'Gesamtbetrag',
  'store.order.will.earning': 'Verdienen',
  'store.order.profit': 'Gewinn',
  'store.order.dividends': 'Dividenden',
  'store.order.payment.status': 'Zahlungsstatus',
  'store.order.seller.buy.status': 'Kaufstatus',
  'store.order.status': 'Bestellstatus',
  'store.order.date': 'Bestelldatum',
  'store.order.purchase.date': 'Kaufdatum',
  'store.order.action': 'Aktion',
  'store.order.purchase.amount': 'Kaufbetrag',

  //Einkommen
  'income.create.at': 'Rekordzeit',
  'income.order.sn': 'Bestellnummer',
  'income.realpay': 'Bestellbetrag',
  'Einkommen.Gewinn': 'Einkommen',

  //Einstellung
  'setting.avatar': 'Business-Avatar',
  'setting.upload': 'Hochladen',
  'setting.shop.name': 'Shop-Name',
  'setting.shop.phone': 'Telefon kontaktieren',
  'setting.shop.address': 'Geschäftsadresse',
  'setting.shop.save': 'Speichern',
  'setting.upload.pic': 'Bild hochladen',
  'setting.send.pic': 'Bild senden',

  //Verkäufer
  'beseller.title': 'Bewerben Sie sich als Händler',
  'beseller.account': 'Kontoinformationen',
  'beseller.store': 'Store-Informationen',
  'beseller.store.name': 'Geschäftsname',
  'beseller.store.address': 'Geschäftsadresse',

  //Zuhause speichern
  'store.home.title': 'Homepage-Einstellungen',
  'store.home.topimg': 'Bild oben',
  'store.home.banner': 'Karussell',
  'store.home.up3': 'Bemerkungen: Mindestens 3 Uploads',
  'store.upload.more': 'mehrere Bilder hochladen',
  'store.home.columns': 'Spalten',
  'store.home.bgimg': 'Hintergrundbild',
  'store.goods.remark': 'Anmerkung: 5 Artikel pro Zeile, maximal 10 Artikel pro Abschnitt',
  'store.home.select': 'Produkt auswählen',
  'store.home.add': 'Spalte hinzufügen',

  //Persönliche Angaben
  'setting.update.user': 'persönliche Informationen',
  'setting.update.info': 'Informationen ändern',
  'setting.user.avatar': 'Benutzer-Avatar',
  'setting.user.nickname': 'Benutzer-Spitzname',
  'setting.user.nickname.desc': 'Bitte Benutzernamen eingeben',

  'setting.safe.title': 'Sicherheitshinweise',
  'setting.user.passwd': 'Passwort ändern',
  'setting.passwd.title1': 'Altes Passwort',
  'setting.passwd.desc1': 'Bitte geben Sie das alte Passwort ein',
  'setting.passwd.title2': 'Neues Passwort',
  'setting.passwd.desc2': 'Bitte geben Sie ein neues Passwort ein',
  'setting.passwd.title3': 'Passwort bestätigen',
  'setting.passwd.desc3': 'Bitte geben Sie das Bestätigungspasswort ein',
  'setting.invitation': 'Freunde einladen',
  'setting.copy': 'Kopieren',

  'adv.add.products': 'Werbeartikel hinzufügen',
  'adv.list.title': 'Produktwerbung',
  'adv.type': 'Werbeaktionstyp',
  'adv.begin.time': 'Aktionszeit',
  'adv.end.time': 'Endzeit',
  'adv.status': 'Status',
  'adv.status.s1': 'Werbung',
  'adv.status.s2': 'Ende',
  'adv.add.back': 'Zur beworbenen Liste zurückkehren',
  'adv.recharge': 'Aktionspunkte aufladen',
  'adv.select.goods': 'Waren auswählen',
  'adv.select.btn': 'Beworbene Produkte',
  'adv.recharge.title': 'Aktionspunkte aufladen',
  'adv.recharge.balance': 'Punktestand',
  'adv.point': 'point',
  'adv.point.rate': 'Punkteverhältnis',
  'adv.recharge.amount': 'Aufladebetrag',
  'adv.recharge.input.amount': 'Aufladebetrag eingeben',
  'adv.select.mod': 'Panel auswählen',
  'adv.mod1': 'Empfohlene Spalten',
  'adv.mod2': 'Bevorzugte Spalte',
  'adv.mod3': 'Heißverkaufte Kolumne',
  'adv.mod4': 'Ausgezeichnete Liste',
  'adv.mod5': 'Klassifizierungsliste',
  'adv.mod6': 'Detaillierte Werbung',
  'adv.ge': 'Menge',
  'adv.xs': 'Stunde',
  'adv.xzbk': 'Platte auswählen',
  'adv.syme': 'Verbleibendes Kontingent',
  'adv.tgsc': 'Aktionsdauer',
  'adv.tgjg': 'Aktionspreis',
  'adv.syye': 'Aktueller Saldo',
  'adv.tgye': 'Aktionsguthaben',
  'adv.yj1': 'erwartet',
  'adv.yj2': 'Beförderung starten',
  'adv.pay.point': 'Verbrauchsstellen',

  'wallet.ykcsxf': 'Die Bearbeitungsgebühr wurde abgezogen',
  'wallet.sxf': 'Servicegebühr',
  'wallet.zxje': 'Mindestbetrag',
  'wallet.24hxe': '24-Stunden-Limit',
  'wallet.desc.text': 'Unter keinen Umständen wird der Beamte Sie auffordern, Geld auf ein Konto zu überweisen, noch wird er Sie nach einem Bestätigungscode fragen. Beteiligen Sie sich nicht an illegalen Aktivitäten wie dem Kauf im Namen anderer, Geldwäsche und illegalem Fundraising und hüten Sie sich vor Online-Betrug',
  'wallet.cunbi.text': 'Sie können {name} nur an diese Adresse hinterlegen, andere Assets werden nicht abgerufen',

  'credit.title': 'Kreditlimit',
  'credit.ed': 'Quote',
  'credit.hk': 'Rückzahlung',
  'credit.kyye': 'Verfügbares Guthaben',
  'credit.dqqk': 'Aktuelle Zahlungsrückstände',
  'credit.hkje': 'Rückzahlungsbetrag',
  'credit.hkje.desc': 'Bitte geben Sie den Rückzahlungsbetrag ein',
  'credit.cgje': 'Kaufbetrag',
  'credit.qrz': 'Zertifizierung',
  'credit.srxyj': 'Kreditzahlungsbetrag eingeben',

  'store.gzl': 'folgen',
  'store.wxz': 'Unbegrenzt',

  'auth.smrz': 'Echtnamen-Authentifizierung',
  'auth.rzlx': 'Typ',
  'auth.sfzm': 'Ausweisvorderseite',
  'auth.sffm': 'Die Rückseite des Personalausweises',
  'auth.zsxm': 'richtiger Name',
  'auth.zjhm': 'Dokumentnummer',
  'auth.yyzz': 'Geschäftslizenz',
  'auth.gsmc': 'Firmenname',
  'auth.shz': 'Prüfung',
  'auth.ytg': 'Bestanden',
  'auth.ybh': 'Abgelehnt',
  'auth.zt': 'Status',
  'auth.gr': 'Persönlich',
  'auth.gs': 'Unternehmen',
  'auth.ljtj': 'Authentifizierung beantragen',
  'auth.wrz': 'Nicht authentifiziert',

  'credit.qts': 'Beschreibung anzeigen',
  'store.spss': 'Warenobergrenze',
  'store.dpfh': 'Store-Dividenden',
  'store.qydj': 'Eigenkapitalniveau',
  'store.level': 'level',
  'store.jian': 'Stücke',

  'order.notify.email': 'Aufgrund der E-Mail-Risikokontrolle werden häufig Bestellerinnerungs-E-Mails der Plattform gesendet und abgefangen. Um sie rechtzeitig zu erhalten, wird empfohlen, {e} zum Login hinzuzufügen E-Mail-Whitelist',

  'setting.sjxx': 'Informationen',
  'setting.smrz': 'Authentifizierung',
  'setting.aqzx': 'Sicherheit',
  'setting.tzxx': 'Benachrichtigung',
  'setting.dpzx': 'Dekoration',
  'setting.sjqy': 'Eigenkapital',
  'setting.sjdjqy': 'Beschreibung der Vorteile auf Händlerebene',

  'setting.fhbky': 'Die aktuelle Dividende ist nicht verfügbar',
  'setting.spgzyzy': 'Regeln und Richtlinien speichern',

  'desc.dqqy': 'Aktuelles Eigenkapital',
  'desc.xjqy': 'Untergeordnete Interessen',
  'desc.sjqy_0': 'Kreditlimit speichern',
  'desc.sjqy_1': 'Dividenden speichern',
  'desc.sjqy_2': 'Anzahl der Produkte, die veröffentlicht werden können',

  'share.yqlb': 'Einladungsliste',
  'share.zmj': 'Gesamtverkäufer',
  'share.zdd': 'Gesamtbestellung',
  'share.zsy': 'Gesamte Maklereinnahmen',
  'share.wcl': 'unbearbeitet',
  'share.ycl': 'Verarbeitet',
  'share.ywc': 'Abgeschlossen',
  'share.dpmc': 'Geschäftsname',
  'share.ddl': 'Bestellmenge',
  'share.jjsy': 'Maklereinnahmen',
  'share.zts': 'Gesamtzahl der Artikel',

  'chat.state': 'State',
  'chat.zx': 'Online',
  'chat.ljz': 'Verbinden',

  'bind.wallet.title': 'Abhebungsadresse',
  'bind.address.title': 'Adresse hinzufügen',
  'bind.bj': 'Bearbeiten',
  'bind.sc': 'Löschen',
  'bind.qrsc': 'Sind Sie sicher, dass Sie diese Adresse löschen möchten?',
  'bind.qbdz': 'Wallet-Adresse',
  'bind.tjsj': 'Zeit hinzufügen',
  'bind.cz': 'Operation',
  'withdrawal.address.choice': 'Bitte wählen Sie die Auszahlungsadresse!',

  'order.sxsj': 'Effektive Zeit',
  'order.ljsj': 'sofort wirksam',
  'order.dssj': 'Zeitlich wirksam',

  'store.st.zt': 'Store-Status',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Ihr Shop ist normal, bitte machen Sie weiter so',
  'store.st.yc': 'Ausnahme',
  'store.st.yc.desc': 'Ihr Shop hat einige ungewöhnliche Bestellungen oder Kundenbeschwerden erhalten',
  'store.st.dj': 'Einfrieren',
  'store.st.dj.desc': 'Ihr Geschäft birgt ein gewisses Betriebsrisiko und die Gelder sind eingefroren',
  'store.st.jy': 'deaktiviert',
  'store.st.jy.desc': 'Ihr Shop hat gegen die plattformbezogenen Vereinbarungen und Regeln verstoßen und wurde deaktiviert.',

  'task.sy': 'Homepage',
  'task.rwlbo': 'Aufgabenliste',
  'task.rwxqo': 'Aufgabendetails',
  'task.ljcy': 'Jetzt teilnehmen',
  'task.gdrw': 'Mehr Aufgaben',
  'task.rwlb': 'Liste der Greifaufgaben',
  'task.qbrw': 'Alle Aufgaben',
  'task.wcyd': 'Ich habe teilgenommen an',
  'task.qdz': 'Bestellungen erhalten',
  'task.ywc': 'Abgeschlossen',
  'task.djy': 'zu handeln',
  'task.ddje': 'Bestellbetrag',
  'task.cyrs': 'Anzahl der Teilnehmer',
  'task.ewsy': 'Zusätzliche Vorteile',
  'task.jzsj': 'Frist',
  'task.ckxq': 'Details anzeigen',
  'task.rwxq': 'Aufgabendetails',
  'task.cylb': 'Teilnahmeliste',
  'task.qdcyrw': 'Sind Sie sicher, dass Sie an dieser Bestellaufgabe teilnehmen möchten?',
  'task.sd': 'Ja',
  'task.bue': 'nein',

  'task.wdsy': 'Umsatz',
  'task.wdfh': 'Dividenden',
  'task.wdqd': 'Bestellung abrufen',
  'task.dd.leixin': 'Auftragstyp',
  'task.dd.qdrw': 'Schnellbestellaufgabe',
  'task.dd.ptdd': 'Gewöhnliche Reihenfolge',

  'flow.lltg': 'Flow Promotion',
  'flow.gmll': 'Datenpaket kaufen',
  'flow.dqtc': 'Aktuelles Paket',
  'flow.tc1': 'Paket',
  'flow.sy2': 'verbleibend',
  'flow.tian3': 'Tag',
  'flow.jlzs': 'Gesamtzahl der Datensätze',
  'flow.lltc': 'Flow-Paket',
  'flow.ll': 'flow',
  'flow.gmsj': 'Kaufzeit',
  'flow.tcjg': 'Paketpreis',
  'flow.sjzf': 'tatsächliche Zahlung',
  'flow.tcsm': 'Paketbeschreibung',
  'flow.tcsm.sm': 'Der Kauf dieses Pakets erhöht die Bekanntheit des Shops und steigert den Kundenverkehr. Um mehr Bestellungen zu erhalten und den Ladenumsatz zu steigern. ',

  'flow.tcmc': 'Paketname',
  'flow.gmsc': 'Kaufdauer',
  'flow.zje': 'Gesamtbetrag',
  'flow.jzsj': 'Frist',
  'flow.gmrq': 'Kaufdatum',
  'flow.tian1': 'Tag',
  'flow.zhou1': 'Woche',
  'flow.yue1': 'Monat',

  'share.dj': 'level',
  'share.hz': 'Übertragen',
  'share.hzd': 'Übertragen an',
  'share.qbye': 'Wallet-Guthaben',
  'share.tgje': 'Aktionsbetrag',
  'share.hzje': 'Überweisungsbetrag',
  'share.qsrhzje': 'Bitte geben Sie den Überweisungsbetrag ein',

  '_inv._title': 'Einladungsbeschreibung',
  '_inv._t1': '1. Einladung und eingeladene Beziehung',
  '_inv._desc1': 'A lädt B ein, B lädt C ein, C lädt D ein. Die höchste Stufe ist 4, wenn D E erneut einlädt, dann hat E nichts mit A zu tun. ',
  '_inv._t2': '2. Der Einladende erhält eine Belohnung',
  '_inv._desc2': 'A erhält 4 % der durch Auftrag B erledigten Gesamtmenge, A erhält 2 % der durch Auftrag C erledigten Gesamtmenge und A erhält 1 % der durch Auftrag D erledigten Gesamtmenge',

  '_index._gm': 'Kauf',
  '_index._sm': 'Beschreibung: Ladenpräsenz erhöhen und Traffic anziehen',

  '_shop._update': '{n} Mal/Monat, {m} Mal im aktuellen Monat geändert',
  '_chat._hc': 'Zurückziehen',

  '_footer._suom': 'Richtlinienbeschreibung',

  '_footer._platform._qualification': 'Firmenqualifikation',
  '_store._ptzz': 'ShopifyOutlets Global-Plattformbezogene Qualifikationen',

  '_abt._gywm': 'Über uns',
  '_abt._syms': 'Geschäftsmodell',
  '_abt._qyzr': 'Unternehmerische Verantwortung',
  '_abt._lxwm': 'Kontaktieren Sie uns',

  '_wsj._wsjms': 'Feiern Sie Halloween mit Stil in unserem gruseligen Halloween-Shop. Egal, ob Sie in dieser gruseligen Jahreszeit etwas Süßes oder Saures suchen, in unserer Kollektion an Halloween-Kostümen, Dekorationen und Accessoires ist für die ganze Familie etwas dabei. Basteln Setzen Sie mit unseren Damen- und Herrenkollektionen ein gruseliges Statement oder entfesseln Sie die Fantasie Ihres Kindes mit Kostümkostümen. Von unvergesslichen süßen Haushaltswaren und Spielzeugen bis hin zu glamouröser Schönheit und Kosmetik – finden Sie die perfekten Stücke, um die aufregendsten Feiertage des Jahres zu feiern. ',

  '_wsj._b1': 'Herbst-Wohndekoration',
  '_wsj._b2': 'Kürbis',
  '_wsj._b3': 'Home',
  '_wsj._b4': 'Kinder',
  '_wsj._b5': 'Baby',
  '_wsj._b6': 'Damen',
  '_wsj._b7': 'Fancy Dress',
  '_wsj._b8': 'Personalisiert',

  '_wsj._cj': 'Teilnehmen',
  '_wsj._gz': 'Aktivitätsregeln',
  '_wsj._flss': 'Kategoriesuche',
  '_wsj._wsnz': 'Halloween-Damenbekleidung',
  '_wsj._wsn': 'Halloween-Herrenbekleidung',
  '_wsj._wst': 'Halloween-Kinderkleidung',
  '_wsj._wszs': 'Halloween-Dekoration',
  '_wsj._wslp': 'Halloween-Geschenk',

  '_wsj._desc1': '„Halloween-Special, Süßes oder Saures!“ Diesen Oktober haben wir eine Karnevalsparty für Sie vorbereitet! Kommen Sie und schnappen Sie sich alle möglichen gruseligen und schockierenden Produkte, es warten große Rabatte auf Sie! ',
  '_wsj._lkm': 'Einkaufen gehen',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween steht vor der Tür',
  '_wsj._mzbt': 'Beauty-Adventskalender',
  '_wsj._mznr': 'Der Countdown für die schönste Zeit des Jahres ist gerade gestartet mit Adventskalendern für jeden Geschmack.',

  '_chr._tt1': 'Alle Weihnachten shoppen',
  '_chr._tt2': 'Weihnachtsdekorationen',
  '_chr._tt3': 'Alle Geschenke shoppen',
  '_chr._tt4': 'Alle Weihnachtsnachtwäsche shoppen',

  '_chr._dt1': 'Die Vorweihnachtszeit ist genauso magisch wie der große Tag. Sortieren Sie also Ihre Geschenke, schmücken Sie Ihren Flur und bereiten Sie Ihren passenden Pyjama für den Weihnachtszauber vor.',

  '_chr._dtt1': 'Weihnachtskleidung',
  '_chr._dtt2': 'Passendes Familienweihnachten',
  '_chr._dtt3': 'Adventskalender',
  '_chr._dtt4': 'Weihnachten zu Hause',
  '_chr._dtt5': 'Gemütlicher Laden',
  '_chr._dtt6': 'Möbel rechtzeitig zu Weihnachten',

  '_chr._tre1': 'Jetzt im Trend',
  '_chr._tre2': 'Fügen Sie Ihrem Zuhause ein bisschen festlichen Spaß mit den Trends hinzu, die jeder liebt.',
  '_chr._tre3': 'Lebkuchen',
  '_chr._tre4': 'Weihnachtspudding',
  '_chr._tre5': 'Christmas Gonks',

  '_chr._ttre1': 'Karten & Geschenkpapier',
  '_chr._ttre2': 'Spielzeug & Spiele',
  '_chr._ttre3': 'Essens- und Getränkegeschenke',
  '_chr._ttre4': 'Strumpffüller',
  '_chr._ttre5': 'Geheimer Weihnachtsmann',
  '_chr._ttre6': 'Babys erstes Weihnachten',
  '_chr._ttre7': 'Schönheitsgeschenke',
  '_chr._ttre8': 'Premium-Geschenke',
  '_chr._ttre9': 'Personalisierte Geschenke',

  '_sdj._sdnz': 'Damenbekleidung',
  '_sdj._sdn': 'Herrenbekleidung',
  '_sdj._sdetlw': 'Geschenk',
  '_sdj._sdzs': 'Dekoration',
  '_sdj._sdjj': 'Spielzeug',

  '_sdj._title1': 'Weihnachten steht vor der Tür',
  '_sdj._desc1': '„Weihnachten: die Zeit des Gebens und der Dankbarkeit.“ Diesen Dezember haben wir ein farbenfroh dekoriertes und warmes Familienfest für Sie vorbereitet! Kommen Sie und holen Sie sich eine Auswahl an hellen und warmen Produkten mit großen Rabatten, die auf Sie warten! ',

  '_ssd._lhpp': 'Flexible Zahlung',
  '_ssd._lzhq': 'Quelle global',
  '_ssd._pwyx': 'als ausgezeichnet bewertet',
  '_ssd._qsth': 'Einfache Rückgabe',
  '_ssd._jgppcn': 'Preisanpassung und Verpflichtung',
  '_ssd._ztbt': 'Erstellen Sie ein globales One-Stop-Shopping',
  '_ssd._yzspl': 'Beschaffung von Inventar direkt von über 8.000 unabhängigen Marken',
  '_ssd._zcgm': 'Zum Kauf registrieren',
  '_ssd._zccs': 'Zum Verkauf registrieren',

  '_ssd._dlwz': 'Anmelden und registrieren',

  '_ssd._qjrgwc': 'Alles in den Warenkorb legen',

  '_zddy._gzlb': 'Beobachtungsliste',
  '_zddy._zsersxs': 'Verkauft bei shopifyoutlets',
  '_zddy._zccwsj': 'Als Händler registrieren',
  '_zddy._dlyh': 'Anmelden',
  '_zddy._zcyh': 'Registrieren',
  '_zddy._xyh': 'Neuer Benutzer',
  '_zddy._gwc': 'Warenkorb',
  '_zddy._znxx': 'Nachricht',
  '_zddy._zshy': 'Am beliebtesten',
  '_zddy._ddl': 'Bestellung',
  '_zddy._mysj': 'Keine Daten',
  '_zddy._ckgd': 'Mehr anzeigen',
  '_zddy._tjsp': 'Empfohlene Produkte',
  '_zddy._rmsp': 'Beliebte Produkte, neue Trends, mehr Kaufmöglichkeiten',
  '_zddy._xsp': 'Neues Produkt',
  '_zddy._jlzzr': 'Die heißesten Artikel der letzten zwei Wochen',
  '_zddy._ljgm': 'Jetzt kaufen',
  '_zddy._gdsp': 'Weitere beliebte Produkte',
  '_zddy._ssbt': 'Suchen',
  '_zddy._ssgjz': 'Ich suche...',

  '_zddy._bdhb': 'Lokale Währung',
  '_zddy._xzbdhb': 'Lokale Währung auswählen',
  '_zddy._zje': 'Gesamtbetrag',
  '_zddy._xzzffs': 'Bitte wählen Sie die für Sie passende Zahlungsmethode',
  '_zddy._jmhb': 'Kryptowährung',

  '_zddy._cp': 'Produkt',
  '_zddy._sj': 'Geschäftsmann',
  '_zddy._mj': 'Verkäufer',
  '_zddy._fhsy': 'Zurück zur Startseite',

  '_sjsy._ddpl': 'Bestellliste',
  '_yhqd._czbz': 'Bitte wenden Sie sich vor dem Aufladen an den zuständigen Kundendienst, um die Aufladeinformationen zu bestätigen.',
  '_yhqd._lxkf': 'Kontaktieren Sie den Kundendienst',
  '_yhqd._qsrje': 'Bitte geben Sie den Aufladebetrag ein',
  '_yhqd._wrhyhtd': 'Kein Bankzugang',
  '_yhqd._yhzz': 'Banküberweisung',
  '_yhbt._yhlx': 'Banktyp',
  '_zcyz._sjdxyz': 'Mobile SMS-Verifizierung',
  '_zcyz._sjhm': 'Telefonnummer',
  '_zcyz._srsjhm': 'Geben Sie die Handynummer ein',
  '_zzsd._qsrzzje': 'Bitte geben Sie den Überweisungsbetrag ein',
  '_zzsd._zzje': 'Überweisungsbetrag',
  '_txcd._qsrtxje': 'Bitte geben Sie den Auszahlungsbetrag ein',
  '_txcd._skr': 'Zahlungsempfänger',
  '_txcd._skr._ms': 'Bitte geben Sie den Namen des Karteninhabers ein',
  '_txcd._skzh': 'Nummer des Zahlungskontos',
  '_txcd._skzh._ms': 'Bitte geben Sie die Zahlungskontonummer ein',
  '_txcd._tjyhk': 'Bankkarte hinzufügen',
  '_txcd._txje': 'Auszahlungsbetrag',
  '_txcd._wdyhdz': 'Bankadresse',
  '_txcd._yhdm': 'Code',
  '_txcd._yhdm._ms': 'Bitte geben Sie die Bankleitzahl ein',
  '_txcd._yhdz': 'Adresse',
  '_txcd._yhdz._ms': 'Bitte geben Sie die Bankadresse ein',
  '_txcd._yhlx': 'Typ',
  '_txcd._yhlx._ms': 'Bitte geben Sie den Bankkartentyp ein',
  '_txcd._yhly': 'Routenführung',
  '_txcd._yhly._ms': 'Bitte geben Sie die Bankleitzahl ein',
  '_txcd._yhmc': 'Bank',
  '_txcd._yhmc._ms': 'Bitte geben Sie den Banknamen ein',
  '_txcd._yhtx': 'Bargeldabhebung bei der Bank',

  '_jys._gmjmhb': 'Kryptowährung kaufen',
  '_jys._gmzy': 'Sie können ein Konto registrieren und Kryptowährung kaufen, indem Sie zu den folgenden Börsen gehen und die Richtlinien Ihres aktuellen Landes befolgen. Dann auf das Plattformkonto aufladen! ',

  '_kdydj._bt': 'Einen Laden eröffnen und tolle Preise gewinnen',
  '_kdydj._xyzbt': 'Das Glück dreht sich weiter',
  '_kdydj._yx': '{n} Mal pumpen',
  '_kdydj._xh': 'Verbrauch {n} Punkte',

  '_kdydj._jf': 'Punkte:',
  '_kdydj._zjjl': 'Gewinnerrekord',

  '_kdydj._hdgz': 'Aktivitätsregeln',
  '_kdydj._qx': 'Bitte',
  '_kdydj._dl': 'Anmelden',

  '_kdydj._mjdl': 'Verkäufer-Login',
  '_kdydj._yxh': 'E-Mail',
  '_kdydj._yxhsm': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  '_kdydj._yxhmm': 'Bitte Passwort eingeben',
  '_kdydj._rgmyzh': 'Wenn Sie kein Konto haben',
  '_kdydj._djzc': 'Zum Registrieren klicken',
  '_kdydj._wjmm': 'Passwort vergessen',

  '_kdydj._wdjp': 'Mein Preis',
  '_kdydj._zjts': 'Tipps zum Gewinnen',
  '_kdydj._jx': 'Weiter',
  '_kdydj._czl': 'Gewonnen',

  '_kdydj._ljjl': 'Kumulative Belohnung',
  '_kdydj._ycy': 'Teilgenommen: {n} Mal',
  '_kdydj._cy': 'Nimm {n} Mal teil',
  '_kdydj._lq': 'Empfangen',
  '_kdydj._ylq': 'Empfangen',
  '_kdydj._wdd': 'Nicht erreicht',

  '_dk._dkbt1': 'Unternehmerdarlehen löst das Problem, Geld zu leihen',
  '_dk._dkbt2': 'Bereitstellung von Unternehmenskrediten und Kapitalzirkulationsdienstleistungen',
  '_dk._dkbt3': 'Wir helfen Ihnen bei der Lösung von Kreditproblemen, z. B. bei knappen Mitteln, bei der fehlenden Möglichkeit, Geld zu leihen, und wenn Sie sich schämen, Freunde um Geld zu bitten. Wir bieten während des gesamten Prozesses professionelle Dienstleistungen an und beantworten Ihre Fragen jederzeit Ziel ist es, jeden Kredit transparent zu machen, sodass Kunden ihn schnell und sicher nutzen können. ',
  '_dk._zxsq': 'Online-Bewerbung',
  '_dk._wddk': 'Mein Darlehen',
  '_dk._aqwy': 'Sicherheit und Sorgenfreiheit',
  '_dk._lhed': 'Flexibles Kontingent',
  '_dk._lhedb': 'Betrag: 5,00 bis 500.000, niedrige Zinsen, flexible Rückzahlungsmethoden, keine Vorabgebühren, Online-Kreditvergabe, keine Überprüfungsanrufe, Vertraulichkeit der Informationen und Kreditvergabe am selben Tag. ',
  '_dk._jsfk': 'Schnellkredit',
  '_dk._jsfkb': 'Bestehen Sie die Überprüfung innerhalb der schnellsten Stunde und die Zahlung erfolgt sofort nach der Überprüfung. ',
  '_dk._aqww1': 'Sicher und sorgenfrei',
  '_dk._aqww1b': 'Kein Hypothekendarlehen, keine Anzahlung erforderlich, keine Rückrufe, dies ist eine Privatsache. ',

  '_dk._gywm': 'Über uns',
  '_dk._gywm1': 'Wir engagieren uns für Kreditfinanzdienstleistungen, basierend auf der Geschäftsphilosophie „Kunde zuerst“ und mit der Mission, die Kapitalumschlagsprobleme der Kunden zu lösen. Seit seiner Gründung hat das Unternehmen unzähligen Kunden bei der Lösung des Kapitalumschlags geholfen Probleme. ',
  '_dk._gywm2': 'Betrag: 500 bis 500.000, niedrige Zinsen, flexible Rückzahlungsmethoden, keine Vorabgebühren, Online-Kreditvergabe, keine Überprüfungsanrufe, Vertraulichkeit der Informationen und Kreditvergabe am selben Tag. ',
  '_dk._gywm3': 'Wenn Sie sich kein Geld von Klassenkameraden und Freunden leihen möchten, kommen Sie bitte zu uns. ',
  '_dk._gywm4': 'Für Unternehmerkreditdienstleistungen engagiert',
  '_dk._gywm5': 'Lösen Sie Ihre finanziellen Probleme',
  '_dk._hzhb': 'Partner - Online-Kreditplattform',
  '_dk._smwda': 'Benutzer der globalen Station shopifyoutlets Business kommen aus 112 Ländern auf der ganzen Welt und nutzen die Kryptowährung USDT/ETH/BTC/TRX für die Abwicklung. Kryptowährung ist eine grenzenlose Möglichkeit des Handels und ermöglicht sofortige, kostengünstige Transaktionen auf der ganzen Welt, ohne Wartezeiten und ohne internationale Gebühren. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Alle Rechte vorbehalten',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) ist von der Financial Conduct Authority als Kreditvermittler zugelassen und reguliert und arbeitet ausschließlich mit dem Kreditgeber Home Retail Group CardServices Limited zusammen, der als shopifyoutlets Business Financial Services, shopifyoutlets Business Financial firmiert Services ist von der Financial Conduct Authority zur Kreditvergabe autorisiert und reguliert. Home Retail GroupCard Services Limited ist in England (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Vereinigtes Königreich, registriert. MK9 2NW. ',

  '_dk._cyzjsq': 'Geschäftskreditantrag ausfüllen',
  '_dk._dkll': 'Darlehenszinssatz',
  '_dk._dkll2': 'Beschreibung des Kreditzinssatzes (zum Anzeigen klicken)',
  '_dk._gs1': 'Formel',
  '_dk._gs2': 'Interesse',
  '_dk._gs3': 'Darlehensbetrag',
  '_dk._gs4': 'Jahreszinssatz',
  '_dk._gs5': 'Kreditanzahl in Tagen',
  '_dk._gs6': 'zum Beispiel',
  '_dk._gs7': 'Darlehensbetrag',
  '_dk._gs8': 'Kreditzyklus',
  '_dk._gs9': 'Tag',
  '_dk._fkfs': 'Ausleihmethode',
  '_dk._zhye': 'Kontostand',
  '_dk._dkqx': 'Leihlaufzeit',
  '_dk._dkqx1': 'Leihzeitraum auswählen',
  '_dk._sqje': 'Betragsbetrag',
  '_dk._qsrdk': 'Bitte Darlehen eingeben',
  '_dk._dkje': 'Darlehensbetrag',
  '_dk._gj': 'Nationalität',
  '_dk._qxzgj': 'Bitte Nationalität auswählen',
  '_dk._sqr': 'Bewerber',
  '_dk._qsrsqsm': 'Bitte geben Sie den Namen des Bewerbers ein',
  '_dk._zjhm': 'ID-Nummer',
  '_dk._qsrzjhm': 'Bitte geben Sie die ID-Nummer ein',
  '_dk._zjzp': 'Ausweisfoto',
  '_dk._zjzp1': 'Bitte laden Sie ein Foto der Vorderseite Ihres Ausweises hoch',
  '_dk._zjfm1': 'Bitte laden Sie ein Foto der Rückseite Ihres Ausweises hoch',
  '_dk._zjsc1': 'Bitte laden Sie ein Foto Ihres Personalausweises hoch',
  '_dk._zjzp2': 'Bild auswählen',
  '_dk._pzslt': 'Fotobeispiel',
  '_dk._wytyb': 'Ich habe gelesen und stimme zu',
  '_dk._jkxy': 'Darlehensvertrag',
  '_dk._tjsq': 'Bewerbung abschicken',

  '_dk._wddk2': 'Mein Darlehen',
  '_dk._ydk': 'Ausgeliehen',
  '_dk._cslx': 'Interesse generieren',
  '_dk._yjhk': 'Geschätzte Rückzahlung',
  '_dk._dkjl': 'Darlehensdatensatz',
  '_dk._hkjl': 'Rückzahlungsdatensatz',
  '_dk._sqrq': 'Bewerbungsdatum',
  '_dk._hkzq': 'Rückzahlungszyklus',
  '_dk._gdll': 'Fester Zinssatz',
  '_dk._hkz': 'Rückzahlung läuft',
  '_dk._yhk': 'Zurückgezahlt',
  '_dk._sqz': 'Wird angewendet',
  '_dk._ytg': 'Bestanden',
  '_dk._ybh': 'Abgelehnt',
  '_dk._tqhk': 'Vorzeitige Rückzahlung',

  '_dk._ljhk': 'Sofort zurückzahlen',
  '_dk._qrytqhk': 'Sind Sie sicher, dass Sie die Rückzahlung im Voraus beantragen möchten?',
  '_dk._qrytqhk1': 'Hinweis: Nach erfolgreicher Beantragung wird der eingezahlte Betrag vorrangig direkt an den Kredit zurückgezahlt',
  '_dk._dkxq': 'Darlehensdetails',
  '_dk._dkje1': 'Darlehensbetrag',
  '_dk._yhlx': 'Zinsen fällig',
  '_dk._hkze': 'Gesamtrückzahlung',
  '_dk._sqhkz': 'Rückzahlung beantragen',
  '_dk._zxydbty': 'Bitte lesen Sie es sorgfältig durch und aktivieren Sie das Kontrollkästchen, um der „Darlehensvereinbarung“ zuzustimmen.',
  '_dk._zzsqz': 'Anwenden',

  '_dk._dksq': 'Gutschrift',

  '_dk._cs': 'Parameter',
  '_dk._nllsm': 'Ausgedrückt als Prozentsatz, zum Beispiel: 6,25 %',
  '_dk._dktssm': 'Die Gesamtzahl der Tage vom Beginn bis zum Ende der Kreditgenehmigung',
  '_dk._yndts': 'Die Anzahl der Tage in einem Jahr: normalerweise 365 Tage',
  '_dk._zlj': 'Verspätungsgebühr',

  '_xbb._xz': 'Herunterladen',
  '_xbb._xzapp': 'ShopifyOutlets-App herunterladen',
  '_xbb._smewm': 'Zum Herunterladen den QR-Code scannen',
  '_xbb._syfl': 'Alle Kategorien',
  '_xbb._gdfl': 'Mehr',
  '_xbb._sbbt1': 'Schnelle Lieferung',
  '_xbb._sbbt2': 'Bei Problemen sind Rückerstattungen möglich',
  '_xbb._sbbt3': 'Kostenlose Rücksendungen',
  '_xbb._sbbt4': 'Innerhalb von 90 Tagen',
  '_xbb._tjsp1': 'Empfohlene Produkte',
  '_xbb._tjsp2': 'Viele Rabatte',
  '_xbb._tjsp3': 'Zeitlich begrenzter Rabatt',

  '_xbb._asjgw': 'Einkaufen nach Geschäft',
  '_xbb._gdsp': 'Weitere Produkte',
  '_xbb._ljgm': 'Jetzt kaufen',
  '_xbb._ptbz': 'ShopifyOutlets-Garantie',
  '_xbb._aqzf': 'Sichere Zahlung',
  '_xbb._aqzf1': 'Eine von vielen internationalen Käufern verwendete Zahlungsmethode',
  '_xbb._aqys': 'Sicherheit und Datenschutz',
  '_xbb._aqys1': 'Wir respektieren Ihre Privatsphäre, daher sind Ihre persönlichen Daten sicher',
  '_xbb._mjbh': 'Käuferschutz',
  '_xbb._mjbh1': 'Wenn Ihre Bestellung nicht vor dem erwarteten Datum geliefert wird oder Sie mit der Bestellung nicht zufrieden sind, können Sie eine Rückerstattung erhalten',
  '_xbb._mzsm': 'Warnung/Haftungsausschluss',

  '_xbb._sjxx': 'Geschäftsmann',
  '_xbb._ggms': 'Spezifikation',
  '_xbb._mswa': 'Beschreibung',
  '_xbb._khpl': 'Kundenkommentare',
  '_xbb._zmfk': 'Positives Feedback',
  '_xbb._gzrs': 'Folgen',
  '_xbb._gzrsan': 'Folgen',
  '_xbb._ygzrsan': 'Bereits gefolgt',
  '_xbb._fsxx': 'Nachricht',
  '_xbb._myrpl': 'Noch keine Kommentare',
  '_xbb._llgd': 'Mehr anzeigen',
  '_xbb._pdcl': 'Versprechen',
  '_xbb._myf': 'Kostenloser Versand',
  '_xbb._sdsj': 'Lieferung in ca. 5-10',
  '_xbb._ksjh': 'Schnelle Lieferung',
  '_xbb._bgdstk': 'Rückerstattung für verlorenes Paket',
  '_xbb._shbgtk': 'Wenn der Artikel beschädigt ist, Rückerstattung',
  '_xbb._s30gtk': 'Rückerstattung, wenn nicht innerhalb von 30 Tagen versendet',
  '_xbb._aqyys': 'Sicherheit und Datenschutz',
  '_xbb._wjtrxk': 'Sichere Zahlung: Wir geben Ihre persönlichen Daten nicht ohne Ihre Zustimmung an Dritte weiter. ',
  '_xbb._sfgrxx': 'Sichere persönliche Daten: Wir schützen Ihre Privatsphäre und bewahren Ihre persönlichen Daten sicher auf. ',
  '_xbb._sl': 'Menge',
  '_xbb._kcld': 'Inventar',

  '_xbb._sjrz': 'Händler hat sich eingelebt',
  '_xbb._hezjh': 'Unser Partnerprogramm bietet Ihnen umfassende Marketingunterstützung und -dienstleistungen, und unser Kundendienstteam bietet professionelle Unterstützung und Beratung, um Sie bei der Optimierung Ihrer Produktpräsentation und Verkaufsstrategie zu unterstützen. Machen Sie jetzt mit! Lassen Sie uns größere Geschäftsmöglichkeiten schaffen und gemeinsam wachsen! ',
  '_xbb._xsje': 'Geschäftsinformationen',
  '_xbb._rgsqjs': 'Wenn Sie bereits Verkäufer sind',
  '_xbb._qni': 'Bitte',
  '_xbb._djdl': 'Zum Anmelden klicken',
  '_xbb._dplog': 'LOGO speichern',
  '_xbb._dpmc': 'Name des Shops',
  '_xbb._srdpmc': 'Bitte geben Sie den Shopnamen ein',
  '_xbb._zjhm': 'ID-Nummer',
  '_xbb._srzjhm': 'Bitte geben Sie die ID-Nummer ein',
  '_xbb._zsxm': 'richtiger Name',
  '_xbb._srzsxm': 'Bitte geben Sie Ihren richtigen Namen ein',
  '_xbb._srgj': 'Land',
  '_xbb._srsrgj': 'Bitte geben Sie das Land ein',
  '_xbb._zjz': 'Ausweisfoto',
  '_xbb._zjzm': 'Vorderseite des Dokuments',
  '_xbb._zjfm': 'Rückseite des Zertifikats',
  '_xbb._sczjz': 'Ausweisfoto aufbewahren',
  '_xbb._pssl': 'Fotobeispiel',
  '_xbb._dlyx': 'Anmelde-E-Mail',
  '_xbb._srdlyx': 'Bitte geben Sie Ihre Login-E-Mail-Adresse ein',
  '_xbb._dlmm': 'Login-Passwort',
  '_xbb._srdlmm': 'Bitte geben Sie Ihr Login-Passwort ein',
  '_xbb._qrmm': 'Passwort bestätigen',
  '_xbb._srqrmm': 'Bitte geben Sie das Bestätigungspasswort ein',
  '_xbb._yqm': 'Einladungscode',
  '_xbb._sryqm': 'Geben Sie den Einladungscode ein',
  '_xbb._yxyzm': 'E-Mail-Bestätigungscode',
  '_xbb._sryzm': 'E-Mail-Bestätigungscode eingeben',
  '_xbb._yydbty': 'Ich habe gelesen und bin damit einverstanden',
  '_xbb._rzxy': 'Vergleichsvereinbarung'
}
