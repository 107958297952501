<template>
  <div class="Layout--wrapper--VN8fXDo">
    <div>
      <div data-spm-anchor-id="0.0.0.i0.59134300v6VAFl">
        <div id="c_l6ktyxu8" class="ViewCard--viewCard--1m6uQ8o MenuList--menuListContainer--31BFbsH node-ocl6ktyru8a MenuList--menuFix--1kh-RA6 viewCard" style="background-color: unset">
          <div class="MenuList--menu--S2VVRqD" id="c_l6ktyxu8-menu-scroll" style="height: calc(100vh - 125px)">
            <ul role="listbox" class="next-menu next-ver next-menu-selectable-single MenuList--menuList--jZf8Ug5" aria-multiselectable="false">
              <li role="option" class="next-menu-sub-menu-wrapper" v-for="(vo, i) in docs" :key="i" @click="onDocs(vo)">
                <div role="listitem" aria-expanded="false" tabindex="-1" :class="['next-menu-item', vo.key == key ? 'next-selected' : '']">
                  <div class="next-menu-item-inner">
                    <span class="next-menu-item-text" aria-selected="false">
                      <span class="next-menu-item-text">{{ vo.title }}</span>
                      <i class="n-icon next-icon next-icon-arrow-right next-medium next-menu-icon-arrow next-menu-icon-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!-- 内容区域 -->
          <div class="MenuList--content--1wm6ddI customMenuListContent MenuList--contentMode2--3K6-fpT" id="c_l6ktyxu8-content" style="overflow: auto; min-height: calc(100vh - 205px)">
            <div class="MenuList--headerWrapper--24ETjVf" v-html="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocsVue',
  data() {
    return {
      look_key: '',

      docs: {},
      content: '',
      key: ''
    }
  },
  created() {
    this.look_key = this.$route.query.key
    this.getDocs()
  },
  methods: {
    getDocs() {
      //获取文章
      let that = this
      this.$api.docs.getDocsAll().then((res) => {
        that.docs = res.data
        if (that.look_key) {
          res.data.forEach((item) => {
            if (item.key == that.look_key) {
              that.onDocs(item)
            }
          })
        } else {
          that.onDocs(res.data[0])
        }
      })
    },
    onDocs(docs) {
      this.content = docs.content
      this.key = docs.key
    }
  }
}
</script>

<style>
strong {
  font-weight: 700;
}
</style>
