export default {
  'nav.account': '개인 센터',
  'nav.language': '중국어 간체',
  'signup.success': '가입 성공',

  //오류
  'contact.empty': '연락처 정보는 비워둘 수 없습니다.',
  'phone.country.empty': '전화 번호는 비워둘 수 없습니다.',
  'phone.empty': '휴대전화는 비워둘 수 없습니다.',
  'email.valid': '올바른 이메일을 입력하세요',

  // 기본
  'base.success': '성공',
  'base.save': '저장',
  'base.slide': '슬라이더를 당겨 확인하세요',
  'base.send.code': '인증 코드 보내기',
  'base.send.time': '({time}) 인증 코드 재전송',
  'base.send.success': '성공적으로 보내기',
  'base.view.more': '더 보기',
  'base.Language': '언어',
  'base.previous': '이전 문서',
  'base.next': '다음 페이지',
  'base.total.page': '총 {페이지} 페이지',
  'base.go.to.page': '문서로 이동',
  'base.go': '이동',
  'base.followers': '팔로워',
  'base.follow': '팔로우',
  'base.following': '팔로잉',
  'base.visit.store': '매장 둘러보기',
  'base.contact': '문의하기',
  'base.delivery': '예상 배송 시간: 1-7일',
  'base.search': '검색',
  'base.search.text': '검색할 키워드 입력',
  'base.server': '온라인 고객 서비스',

  //인덱스
  'text.categories.title': '제품 카테고리',
  'text.welcome': '쇼핑에 오신 것을 환영합니다!',
  'text.wishlist': '위시리스트',
  'text.account': '개인 센터',
  'text.orders': '주문',
  'text.messages': '정보',
  'text.suggest.title': '독점 제안',
  'text.suggest.content': '글로벌 프리미엄 신규 회원 전용!',
  'text.super.deals': '믿을 수 없는 가격의 인기 상품.',

  //가입하기
  'signup.register': '가입',
  'signup.signin': '로그인',
  'signup.store.signin': '비즈니스 로그인',
  'signup.sign.out': '로그아웃',
  'signup.email': '이메일 주소',
  'signup.password': '비밀번호',
  'signup.qr.password': '비밀번호 확인',
  'signup.confirm.password': '비밀번호 확인',
  'signup.forgot.password': '비밀번호 찾기',
  'signup.invitation': '초대 코드',
  'signup.char': '문자',
  'signup.contains': '숫자, 문자 또는 기호 포함',
  'signup.qr.contains': '두 비밀번호가 일치하지 않습니다.',
  'signup.create.account': '계정 만들기',
  'signup.agree': '계정 생성, 동의',
  'signup.member.agreement': '회원 계약',
  'signup.and': '그리고',
  'signup.privacy.policy': '개인정보 보호정책',
  'signup.email.code': '이메일 인증',
  'signup.last.step': '마지막 단계',
  'signup.send.email': '{email}로 전송된 4자리 코드를 입력하세요',
  'signup.modify.email': '이메일 수정',
  'signup.verify.email': '확인 이메일',
  'signup.have.store': '판매자 계정 보유',
  'signup.goto.signin': '비즈니스 로그인',
  'signup.no.store': '판매자 계정 없음',
  'signup.goto.store': '가맹점 가입',
  'signup.next': '다음',
  'signup.your.email': '귀하의 이메일',
  'signup.code.text': '인증 코드',
  'signup.submit.signup': '지금 가입하기',
  'signup.smrz': '실명인증',
  'signup.derb': '두 번째 단계',
  'signup.qsrxm': '문서 이름을 입력하세요.',
  'signup.qsrhm': 'ID 번호를 입력하세요',

  //잊어버렸다
  'forgot.title': '비밀번호 재설정',
  'forgot.btn.check': '이메일 인증',
  'forgot.reset.now': '지금 초기화',

  //가게
  'store.info.open': '오픈 {y}주',

  // 바닥글
  'footer.great.value': '훌륭한 가치',
  'footer.great.value.desc': '1억 개가 넘는 항목에 대해 경쟁력 있는 가격을 제공합니다.',
  'footer.shopping': '글로벌 쇼핑',
  'footer.shopping.desc': '저희는 200개 이상의 국가와 지역으로 배송하고 저희 웹사이트는 7개 언어로 제공됩니다.',
  'footer.safe.payment': '안전 결제',
  'footer.safe.payment.desc': '세계에서 가장 인기 있고 안전한 결제 방법으로 결제하세요.',
  'footer.shop.with.confidence': '자신있게 쇼핑하세요',
  'footer.shop.with.confidence.desc': '저희의 구매자 보호 정책은 전체 구매 과정에 적용됩니다.',
  'footer.help.center': '도움말 센터',
  'footer.help.center.desc': '원활한 쇼핑 경험을 위한 연중무휴 지원',
  'footer.terms.conditions': '이용약관',
  'footer.return.policy': '반품 정책',
  'footer.support.policy': '지원 정책',
  'footer.privacy.policy': '개인정보 보호정책',
  'footer.be.seller': '판매자 되기',
  'footer.apply.now': '지금 신청하기',
  'footer.stay.connected': '연결 유지',

  'footer.about.us': '회사 소개',
  'footer.about.company': '회사 프로필',
  'footer.about.video': '동영상 소개',
  'footer.contact': '연락처',

  'footer.my.account': '내 계정',
  'footer.my.logout': '로그아웃',
  'footer.my.order': '주문 내역',
  'footer.my.wish': '내 위시리스트',
  'footer.my.join': '멤버 파트너 되기',
  'footer.email': '이메일',
  'footer.gfemail': '공식 이메일',
  'footer.fwemail': '서비스 이메일',
  'footer.address': '주소',

  'apply.success': '신청 성공',
  'apply.success.desc': '적용되었습니다. 로그인 계정으로 이동하세요.',

  // 카테고리
  'category.title': '관련 카테고리',
  'category.all': '모든 카테고리',

  //세부 사항
  'detail.store.home': '스토어 홈',
  'detail.sale.items': '아이템 목록',
  'detail.recommend': '추천',
  'detail.orders': '판매',
  'detail.quantity': '수량',
  'detail.pieces.available': '사용 가능',
  'detail.delivery': '배송',
  'detail.free.shipping': '무료 배송',
  'detail.estimated.delivery': '예상 배송',
  'detail.days': '일',
  'detail.buy.now': '구매',
  'detail.add.to.cart': '장바구니에 담기',
  'detail.buyer.protection': '구매자 보호',
  'detail.money.guarantee': '환불 보장',
  'detail.refund.desc': '상품이 설명과 다르거나 배송되지 않은 경우 전액 환불 받기',
  'detail.description': '제품 설명',
  'detail.customer.reviews': '고객 리뷰',
  'detail.specifications': '제품 사양',
  'detail.top.selling.products': '베스트 셀러 제품',
  'detail.recommended.for.you': '추천',
  'detail.sold': '판매',
  'detail.receipt': '영수증 확인',
  'detail.receipt.title': '주문 접수를 확인하시겠습니까?',
  'detail.receipt.content': '확인 후 거래 주문 완료',
  'detail.comment': '댓글',

  //확인하다
  'confirm.shipping.address': '수취인 주소',
  'confirm.change': '변경',
  'confirm.payment.methods': '결제 수단',
  'confirm.summary': '정산',
  'confirm.total.item.costs': '항목 합계',
  'confirm.total': '전체',
  'confirm.checkout': '체크아웃',
  'confirm.place.order': '지금 주문하기',
  'confirm.pay.now': '지금 결제하기',
  'confirm.order.desc': "'주문하기'를 클릭한 후 읽고 확인했음을 확인합니다.",
  'confirm.order.policy': '모든 약관 및 정책',
  'confirm.payment': '글로벌 쇼핑은 귀하의 정보 및 결제 보안을 보장합니다',

  // 주소
  'address.title': '받는 사람 주소',
  'address.default': '기본값',
  'address.edit': '수정',
  'address.delete': '삭제',
  'address.new': '새 주소 만들기',
  'address.contact': '연락처',
  'address.address': '주소',
  'address.phone': '전화번호',
  'address.set.default': '기본값으로 설정',
  'address.confirm': '확인',
  'address.cancel': '취소',
  'address.del.title': '주소 삭제를 확인하시겠습니까?',
  'address.del.content': '이 배송 주소를 삭제하시겠습니까?',

  'payment.method': '결제 방법',

  // 쇼핑 카트
  'shopping.title': '장바구니',
  'shopping.back.buy': '특별 쇼핑으로 돌아가기',
  'shopping.start.shopping': '쇼핑 시작',

  // 멤버
  'member.account': '개인 센터',
  'member.overview': '개요',
  'member.orders': '주문',
  'member.payment': '내 지갑',
  'member.address': '배송 주소',
  'member.wishlist': '위시리스트',
  'member.followlist': '팔로우리스트',
  'member.message.center': '메시지 센터',
  'member.setting': '개인 설정',
  'member.shop.info': '상점 정보',
  'member.shop.setting': '상점 설정',
  'member.order.notify': '새 주문 알림',
  'member.order.tips': '판매자 계정 이메일로 이메일 전송',

  // 회원 주문
  'member.order.title': '내 주문',
  'member.order.view.all': '모두 보기',
  'member.order.all': '모두',
  'member.order.unpaid': '미결제',
  'member.order.paid': '지불',
  'member.order.procurement': '보류 중인 조달',
  'member.order.seller.paid': '구매',
  'member.order.processing': '보류 중',
  'member.order.shipped': '배송됨',
  'member.order.return': '반품 중',
  'member.order.completed': '완료',
  'member.order.refund': '환불됨',
  'member.order.all.time': '모두',
  'member.order.empty': '아직 주문이 없습니다',
  'member.order.date': '날짜',
  'member.order.purchase.date': '구매',
  'member.order.cpoy': '복사',
  'member.order.id': '주문',
  'member.order.detail': '주문 세부정보',
  'member.order.logistics': '물류 정보',

  // 회원 결제
  'member.payment.title': '내 지갑',
  'member.wallet.balance': '지갑 잔액',
  'member.crypto.recharge': '재충전',
  'member.crypto.withdrawal': '철회하다',
  'member.crypto.bank': '은행 카드',
  'member.wallet.record': '코인 입금 기록',
  'member.bankcard.record': '은행 카드 기록',
  'member.withdrawal.record': '탈퇴 기록',
  'member.income.record': '소득 기록',
  'member.transaction.record': '거래 기록',
  'member.wallet.freeze': '자금 동결',
  'member.wallet.profit': '예상 수입',

  //재충전
  'recharge.currency': '통화',
  'recharge.protocol': '프로토콜',
  'recharge.qrcode': 'QR 코드',
  'recharge.address': '지갑 주소',
  'recharge.copy': '주소 복사',
  'recharge.ok.text': '확인',
  'recharge.cancel.text': '취소',

  //은행
  'bank.recharge.title': '은행 카드 충전',
  'bank.title': '은행 이름',
  'bank.name': '이름',
  'bank.account': '계좌',
  'bank.routing': '라우팅',
  'bank.code': '코드',
  'bank.bankname': '은행 이름',
  'bank.bankaddress': '은행 주소',

  'bank.ok.text': '다음',
  'bank.ok.prev': '이전 단계',
  'bank.submit': '지금 제출',
  'bank.amount': '충전 금액',
  'bank.amount.desc': '충전 금액을 입력하세요',
  'bank.type': '유형',
  'bank.usd': 'USD',
  'bank.eur': '유로',
  'bank.receipt.number': '영수증 번호',
  'bank.receipt.number.desc': '거래 영수증 번호를 입력하세요',
  'bank.credential.picture': '자격증명 사진',
  'bank.credential.picture.desc': '자격증명 사진을 업로드하세요',
  'bank.remark': '비고',
  'bank.upload': '사진 업로드',
  'bank.text.title': '메모',
  'bank.text.t1': '이체에 대한 설명은 필요하지 않습니다. 공란으로 두십시오.',
  'bank.text.t2': '홍콩 가정의 경우 중국 대신 홍콩을 선택하십시오.',
  'bank.text.t3': '미국송금, 평일 오후 3시 이전 유럽송금, 은행 근무시간 내 송금 가능!',
  'bank.text.t4': '결제 시간은 T+1이며, 유럽의 최신 결제는 T+3입니다.',
  'bank.text.t5': '계좌를 사용할 수 있는지 확인하기 위해 돈을 이체하기 전에 플랫폼에 알리십시오. 계좌가 폐쇄되면 보상이 지급되지 않습니다.',
  'bank.text.t6': '은행 카드를 통한 입금. 고객 서비스 정보가 우선합니다.',

  //은행 목록
  'bank.list.title': '은행 이름',
  'bank.list.code': '은행 코드',
  'bank.list.amount': '이체 금액',
  'bank.list.number': '영수증 번호',
  'bank.list.img': '바우처 이미지',
  'bank.list.status': '상태',
  'bank.list.time': '시간',
  'bank.list.status1': '검토 중',
  'bank.list.status2': '통과',
  'bank.list.status3': '거부됨',

  // 출금
  'withdrawal.address.desc': '지갑 주소를 입력하세요!',
  'withdrawal.number': '개수',
  'withdrawal.real.number': '실제 도착',
  'withdrawal.number.desc': '출금 금액을 입력하세요!',
  'withdrawal.btn.all': '모두',
  'withdrawal.balance': '잔고',
  'withdrawal.commission': '수수료',
  'withdrawal.actual.amount': '계정',
  'withdrawal.notice': '공지',
  'withdrawal.notice.text': '이체하기 전에 수령 주소 정보가 정확한지 확인하십시오. 자산이 양도되면 반환되지 않습니다.',
  'withdrawal.notice.content': '{name}({cp_name}) 커미션: 현재 시장 가치 {fee_rate}%/거래, 최소 기준: {fee_min} {name}/거래',
  'withdrawal.submit': '제출',
  'withdrawal.choice': '암호화폐 선택',
  'withdrawal.yzm': '인증 코드',
  'withdrawal.fs': '보내기',
  'withdrawal.qsryzm': '이메일 인증 코드를 입력하세요',

  // 수신
  'recive.method': '프로토콜',
  'recive.amount': '금액',
  'recive.address': '주소',
  'recive.date': '시간',
  'recive.status': '상태',
  'recive.create.at': '거래 시간',
  'recive.type': '유형',
  'recive.befor': '거래 전',
  'recive.balance': '잔고',
  'recive.freeze': '정지',
  'recive.review': '검토 중',
  'recive.success': '통과',
  'recive.reject': '거부됨',

  // 광고
  'advertise.title': '프로모션 센터',
  'advertise.shop.title': '쇼핑 프로모션',
  'advertise.shop.status': '상태',
  'advertise.shop.expired': '만료됨',
  'advertise.shop.promotion': '홍보하기',
  'advertise.shop.expire.date': '만료일',
  'advertise.shop.renew': '광고 갱신',
  'advertise.shop.payable': '지불 금액',
  'advertise.shop.explanation': '광고 설명',
  'advertise.shop.text': '플랫폼 광고 프로모션 참여, 매장 노출 증가, 거래 주문 촉진',
  'advertise.shop.paynow': '즉시 갱신',
  'advertise.shop.modal.title': '결제 확인',
  'advertise.shop.modal.desc': '프로모션 수수료 지불 확인',
  'advertise.shop.modal.btn': '결제 확인',

  //위시리스트
  'wishlist.title': '위시리스트',
  'wishlist.delete': '삭제',
  'wishlist.orders': '판매',

  // 팔로우 목록
  'followlist.title': '팔로리스트',
  'followlist.delete': '삭제',
  'followlist.follow': '팔로우',

  // 가게
  'store.dashboard': '대시보드',
  'store.products': '제품',
  'store.products.list': '제품 목록',
  'store.products.reviews': '제품 리뷰',
  'store.orders': '주문',
  'store.wallet': '지갑',
  'store.message': '메시지 센터',
  'store.setting': '설정',
  'store.order.total.profit': '총 예상 영업이익',

  //계기반
  'dashboard.store.hour.views': '실시간 방문',
  'dashboard.store.today.views': '오늘의 보기',
  'dashboard.product.total': '총 제품',
  'dashboard.product.today': '오늘 추가됨',
  'dashboard.order.total': '주문 합계',
  'dashboard.sales.total': '예상 회전율',
  'dashboard.sales.real': '실제 판매',
  'dashboard.sales.pay': '총 지불금',
  'dashboard.sales.profit': '총 이익',
  'dashboard.commission.total': '총 수익',
  'dashboard.commission.today': '오늘의 수입',
  'dashboard.order.sales': '제품 판매',
  'dashboard.sales.list': '판매 순위',
  'dashboard.goods.cate.rate': '제품 카테고리 비율',
  'dashboard.goods.wish': '좋아요 상품 순위',
  'dashboard.january': '1월',
  'dashboard.february': '2월',
  'dashboard.march': '3월',
  'dashboard.april': '4월',
  'dashboard.may': '5월',
  'dashboard.june': '6월',
  'dashboard.july': '7월',
  'dashboard.august': '8월',
  'dashboard.september': '9월',
  'dashboard.october': '10월',
  'dashboard.november': '11월',
  'dashboard.december': '12월',

  // 제품
  'products.add.new': '새 제품 추가',
  'products.add.from.warehouse': '창고에서 제품 추가',
  'products.delete': '삭제',
  'products.add': '추가',
  'products.table.img': '이미지',
  'products.table.name': '제품 이름',
  'products.table.category': '카테고리',
  'products.table.wish': '좋아요',
  'products.table.stock': '재고',
  'products.table.price': '가격',
  'products.table.profit': '이익',
  'products.table.action': '작업',
  'products.search.category': '카테고리로 검색',
  'products.back.product': '제품 목록으로 돌아가기',
  'products.total': '총 제품',
  'products.yes': '예',
  'products.no': '취소',
  'products.batch.add': '일괄 추가',
  'products.ask.add': '제품을 추가하시겠습니까?',
  'products.batch.delete': '일괄 삭제',
  'products.ask.delete': '정말 제품을 삭제하시겠습니까?',
  'products.top': '상위',
  'products.syzd': '홈페이지 상단',
  'products.zdwz': '최상위 게재순위',
  'products.t1': '프로모션',
  'products.t2': '선호',
  'products.t3': '부티크',
  'products.t4': '없음',

  // 리뷰
  'reviews.title': '제품 리뷰',
  'reviews.product.name': '제품',
  'reviews.user.name': '사용자',
  'reviews.star': '평점',
  'reviews.comment': '리뷰 콘텐츠',
  'reviews.sku': '제품 사양',
  'reviews.imgs': '사진 갤러리',
  'reviews.created': '날짜',

  // store.order
  'store.order.purchase': '지금 구매하기',
  'store.order.purchase.desc': '정말로 이 제품을 결제하시겠습니까?',
  'store.order.purchase.yes': '즉시 지불',
  'store.order.purchase.no': '취소',
  'store.order.desc': '이 주문을 구매하려면 결제가 필요하며 주문이 완료되면 혜택을 받게 됩니다.',
  'store.order.no': '주문 번호',
  'store.order.number': '수량',
  'store.order.buyer': '구매자',
  'store.order.total': '총 금액',
  'store.order.will.earning': '적립',
  'store.order.profit': '이익',
  'store.order.dividends': '배당금',
  'store.order.payment.status': '결제 상태',
  'store.order.seller.buy.status': '구매 상태',
  'store.order.status': '주문 상태',
  'store.order.date': '주문 날짜',
  'store.order.purchase.date': '구매 날짜',
  'store.order.action': '작업',
  'store.order.purchase.amount': '구매 금액',

  //소득
  'income.create.at': '기록 시간',
  'income.order.sn': '주문 번호',
  'income.realpay': '주문 금액',
  'income.profit': '소득',

  //환경
  'setting.avatar': '비즈니스 아바타',
  'setting.upload': '업로드',
  'setting.shop.name': '상점 이름',
  'setting.shop.phone': '연락처 전화',
  'setting.shop.address': '상점 주소',
  'setting.shop.save': '저장',
  'setting.upload.pic': '사진 업로드',
  'setting.send.pic': '사진 보내기',

  //베셀러
  'beseller.title': '판매자 신청',
  'beseller.account': '계정 정보',
  'beseller.store': '매장 정보',
  'beseller.store.name': '상점 이름',
  'beseller.store.address': '매장 주소',

  //저장홈
  'store.home.title': '홈페이지 설정',
  'store.home.topimg': '상단 이미지',
  'store.home.banner': '캐러셀',
  'store.home.up3': '비고: 최소 3개 업로드',
  'store.upload.more': '여러 사진 업로드',
  'store.home.columns': '열',
  'store.home.bgimg': '배경 이미지',
  'store.goods.remark': '비고: 한 줄에 5개 항목, 섹션당 최대 10개 항목',
  'store.home.select': '제품 선택',
  'store.home.add': '열 추가',

  //개인 정보
  'setting.update.user': '개인 정보',
  'setting.update.info': '정보 수정',
  'setting.user.avatar': '사용자 아바타',
  'setting.user.nickname': '사용자 닉네임',
  'setting.user.nickname.desc': '사용자 닉네임을 입력하세요',

  'setting.safe.title': '안전 정보',
  'setting.user.passwd': '비밀번호 변경',
  'setting.passwd.title1': '이전 비밀번호',
  'setting.passwd.desc1': '기존 비밀번호를 입력하세요',
  'setting.passwd.title2': '새 비밀번호',
  'setting.passwd.desc2': '새 비밀번호를 입력하세요',
  'setting.passwd.title3': '비밀번호 확인',
  'setting.passwd.desc3': '확인 비밀번호를 입력하세요',
  'setting.invitation': '친구 초대',
  'setting.copy': '복사',

  'adv.add.products': '프로모션 제품 추가',
  'adv.list.title': '제품 프로모션',
  'adv.type': '프로모션 유형',
  'adv.begin.time': '프로모션 시간',
  'adv.end.time': '종료 시간',
  'adv.status': '상태',
  'adv.status.s1': '홍보 중',
  'adv.status.s2': '종료',
  'adv.add.back': '승격 목록으로 돌아가기',
  'adv.recharge': '프로모션 포인트 충전',
  'adv.select.goods': '상품 선택',
  'adv.select.btn': '프로모션 제품',
  'adv.recharge.title': '프로모션 포인트 충전',
  'adv.recharge.balance': '포인트 잔액',
  'adv.point': '포인트',
  'adv.point.rate': '포인트 비율',
  'adv.recharge.amount': '충전 금액',
  'adv.recharge.input.amount': '충전 금액 입력',
  'adv.select.mod': '패널 선택',
  'adv.mod1': '추천 열',
  'adv.mod2': '선호하는 열',
  'adv.mod3': '뜨거운 판매 칼럼',
  'adv.mod4': '훌륭한 목록',
  'adv.mod5': '분류 목록',
  'adv.mod6': '상세 프로모션',
  'adv.ge': '수량',
  'adv.xs': '시간',
  'adv.xzbk': '플레이트 선택',
  'adv.syme': '남은 할당량',
  'adv.tgsc': '프로모션 기간',
  'adv.tgjg': '프로모션 가격',
  'adv.syye': '현재 잔액',
  'adv.tgye': '프로모션 잔액',
  'adv.yj1': '예상',
  'adv.yj2': '프로모션 시작',
  'adv.pay.point': '사용 포인트',

  'wallet.ykcsxf': '수수료가 차감되었습니다',
  'wallet.sxf': '서비스 수수료',
  'wallet.zxje': '최소 금액',
  'wallet.24hxe': '24시간 제한',
  'wallet.desc.text': '어떠한 상황에서도 공무원은 계좌로 돈을 이체하도록 요청하지 않으며 인증 코드를 요청하지 않습니다. 대리구매, 자금세탁, 불법자금모금 등 불법행위에 가담하지 마시고, 온라인 사기에 주의하시기 바랍니다.',
  'wallet.cunbi.text': '이 주소에는 {name}만 입금할 수 있으며 다른 자산은 검색되지 않습니다.',

  'credit.title': '신용 한도',
  'credit.ed': '할당량',
  'credit.hk': '상환',
  'credit.kyye': '사용 가능한 잔액',
  'credit.dqqk': '현재 연체금',
  'credit.hkje': '상환 금액',
  'credit.hkje.desc': '상환금액을 입력하세요',
  'credit.cgje': '구매 금액',
  'credit.qrz': '인증',
  'credit.srxyj': '신용 결제 금액 입력',

  'store.gzl': '팔로우',
  'store.wxz': '무제한',

  'auth.smrz': '실명인증',
  'auth.rzlx': '유형',
  'auth.sfzm': '신분증 앞면',
  'auth.sffm': '신분증 뒷면',
  'auth.zsxm': '실명',
  'auth.zjhm': '문서 번호',
  'auth.yyzz': '사업자 등록증',
  'auth.gsmc': '회사 이름',
  'auth.shz': '감사',
  'auth.ytg': '통과',
  'auth.ybh': '거부됨',
  'auth.zt': '상태',
  'auth.gr': '개인',
  'auth.gs': '엔터프라이즈',
  'auth.ljtj': '인증 신청',
  'auth.wrz': '인증되지 않음',

  'credit.qts': '설명 보기',
  'store.spss': '상품 상한가',
  'store.dpfh': '매장 배당금',
  'store.qydj': '자산 수준',
  'store.level': '레벨',
  'store.jian': '조각',

  'order.notify.email': '이메일 위험 통제로 인해 플랫폼 주문 알림 이메일이 자주 전송되고 차단될 수 있습니다. 적시에 수신하려면 로그인에 {e}을 추가하는 것이 좋습니다. 이메일 화이트리스트',

  'setting.sjxx': '정보',
  'setting.smrz': '인증',
  'setting.aqzx': '보안',
  'setting.tzxx': '알림',
  'setting.dpzx': '장식',
  'setting.sjqy': '자산',
  'setting.sjdjqy': '가맹점 등급 혜택 설명',

  'setting.fhbky': '현재 배당금을 사용할 수 없음',
  'setting.spgzyzy': '저장 규칙 및 지침',

  'desc.dqqy': '현재 자산',
  'desc.xjqy': '하위 관심사',
  'desc.sjqy_0': '스토어 신용 한도',
  'desc.sjqy_1': '점포 배당금',
  'desc.sjqy_2': '게시할 수 있는 제품 수',

  'share.yqlb': '초대 목록',
  'share.zmj': '총 판매자',
  'share.zdd': '총 주문',
  'share.zsy': '총 중개수입',
  'share.wcl': '처리되지 않음',
  'share.ycl': '처리됨',
  'share.ywc': '완료',
  'share.dpmc': '상점 이름',
  'share.ddl': '주문 수량',
  'share.jjsy': '중개수입',
  'share.zts': '총 항목 수',

  'chat.state': '상태',
  'chat.zx': '온라인',
  'chat.ljz': '연결 중',

  'bind.wallet.title': '출금 주소',
  'bind.address.title': '주소 추가',
  'bind.bj': '편집',
  'bind.sc': '삭제',
  'bind.qrsc': '이 주소를 삭제하시겠습니까?',
  'bind.qbdz': '지갑 주소',
  'bind.tjsj': '시간 추가',
  'bind.cz': '작업',
  'withdrawal.address.choice': '출금 주소를 선택해주세요!',

  'order.sxsj': '유효 시간',
  'order.ljsj': '즉시 적용됨',
  'order.dssj': '시간 적용',

  'store.st.zt': '매장현황',
  'store.st.zc': '정상',
  'store.st.zc.desc': '상점은 정상입니다. 계속 유지해주세요.',
  'store.st.yc': '예외',
  'store.st.yc.desc': '매장에 비정상적인 주문이 있거나 고객 불만이 접수되었습니다.',
  'store.st.dj': '정지',
  'store.st.dj.desc': '당신의 상점은 특정 운영 위험이 있으며 자금이 동결되었습니다.',
  'store.st.jy': '비활성화됨',
  'store.st.jy.desc': '스토어가 플랫폼 관련 계약 및 규칙을 위반하여 비활성화되었습니다.',

  'task.sy': '홈페이지',
  'task.rwlbo': '작업 목록',
  'task.rwxqo': '작업 세부 정보',
  'task.ljcy': '지금 참여하기',
  'task.gdrw': '더 많은 작업',
  'task.rwlb': '잡는 작업 목록',
  'task.qbrw': '모든 작업',
  'task.wcyd': '참여했습니다',
  'task.qdz': '주문 받기',
  'task.ywc': '완료',
  'task.djy': '거래하기 위해',
  'task.ddje': '주문금액',
  'task.cyrs': '참가자 수',
  'task.ewsy': '추가 혜택',
  'task.jzsj': '마감',
  'task.ckxq': '자세히 보기',
  'task.rwxq': '작업 세부 정보',
  'task.cylb': '참여 목록',
  'task.qdcyrw': '이 주문 작업에 참여하시겠습니까?',
  'task.sd': '예',
  'task.bue': '아니오',

  'task.wdsy': '수익',
  'task.wdfh': '배당금',
  'task.wdqd': '주문 받기',
  'task.dd.leixin': '주문 유형',
  'task.dd.qdrw': '빠른 주문 작업',
  'task.dd.ptdd': '일반 주문',

  'flow.lltg': '흐름 프로모션',
  'flow.gmll': '데이터 패키지 구매',
  'flow.dqtc': '현재 패키지',
  'flow.tc1': '패키지',
  'flow.sy2': '나머지',
  'flow.tian3': '일',
  'flow.jlzs': '총 레코드 수',
  'flow.lltc': '흐름 패키지',
  'flow.ll': '흐름',
  'flow.gmsj': '구매시간',
  'flow.tcjg': '패키지 가격',
  'flow.sjzf': '실제 결제',
  'flow.tcsm': '패키지 설명',
  'flow.tcsm.sm': '이 패키지를 구매하면 상점의 노출이 증가하고 더 많은 고객 트래픽을 얻을 수 있습니다. 더 많은 주문을 받고 매장 수익을 늘리기 위해. ',

  'flow.tcmc': '패키지 이름',
  'flow.gmsc': '구매 기간',
  'flow.zje': '총 금액',
  'flow.jzsj': '마감',
  'flow.gmrq': '구매 날짜',
  'flow.tian1': '일',
  'flow.zhou1': '주',
  'flow.yue1': '월',

  'share.dj': '레벨',
  'share.hz': '전송',
  'share.hzd': '전송 대상',
  'share.qbye': '지갑 잔액',
  'share.tgje': '프로모션 금액',
  'share.hzje': '이체 금액',
  'share.qsrhzje': '이체 금액을 입력하세요.',

  '_inv._title': '초대 설명',
  '_inv._t1': '1. 초대 및 초대 관계',
  '_inv._desc1': 'A는 B를, B는 C를, C는 D를 초대합니다. 가장 높은 레벨은 4이며 D가 E를 다시 초대하면 E는 A와 아무 관련이 없습니다. ',
  '_inv._t2': '2. 초대한 사람은 보상을 받습니다',
  '_inv._desc2': 'A는 주문 B가 완료한 총 금액의 4%, A는 주문 C가 완료한 총 금액의 2%, A는 주문 D가 완료한 총 금액의 1%를 얻습니다.',

  '_index._gm': '구입',
  '_index._sm': '설명: 매장 노출 증가 및 트래픽 유치',

  '_shop._update': '{n}회/월, 이번 달에 {m}회 수정됨',

  '_chat._hc': '철회',

  '_footer._suom': '정책 설명',

  '_footer._platform._qualification': '회사 자격',
  '_store._ptzz': '넥스트 스토어 플랫폼 관련 자격',

  '_abt._gywm': '회사 소개',
  '_abt._syms': '비즈니스 모델',
  '_abt._qyzr': '기업의 책임',
  '_abt._lxwm': '연락처',

  '_wsj._wsjms': '으스스한 할로윈 매장에서 스타일리시하게 할로윈을 축하하세요. 이 으스스한 계절에 즐길 수 있는 트릭이나 간식을 찾고 계시다면, 우리의 할로윈 의상, 장식 및 액세서리 컬렉션에는 온 가족이 즐길 수 있는 것이 있습니다. 등골이 오싹해지는 여성 및 남성 컬렉션을 선택하거나, 멋진 드레스 의상으로 자녀의 상상력을 마음껏 발휘하세요. 잊을 수 없는 귀여운 가정용품과 장난감부터 화려한 뷰티와 화장품에 이르기까지, 올해의 가장 흥미진진한 휴가를 기념할 완벽한 제품을 찾아보세요.',

  '_wsj._b1': '가을 집 장식',
  '_wsj._b2': '호박',
  '_wsj._b3': '집',
  '_wsj._b4': '어린이용',
  '_wsj._b5': '아기의 것',
  '_wsj._b6': '여성용',
  '_wsj._b7': '멋진 드레스',
  '_wsj._b8': '맞춤 설정',

  '_wsj._cj': '참여',
  '_wsj._gz': '활동 규칙',
  '_wsj._flss': '범주 검색',
  '_wsj._wsnz': '할로윈 여성 의류',
  '_wsj._wsn': '할로윈 남성 의류',
  '_wsj._wst': '할로윈 아동복',
  '_wsj._wszs': '할로윈 장식',
  '_wsj._wslp': '할로윈 선물',

  '_wsj._desc1': '"할로윈 특집, 트릭 오어 트릿!" 올 10월, 우리는 여러분을 위해 카니발 파티를 준비했습니다! 와서 모든 종류의 무섭고 충격적인 제품을 사세요. 큰 할인이 여러분을 기다리고 있습니다! ',
  '_wsj._lkm': '쇼핑하러 가세요',

  '_wsj._wsj': '할로윈',

  '_wsj._wsjjz': '할로윈이 다가오고 있습니다',
  '_wsj._mzbt': '뷰티 출현 달력',
  '_wsj._mznr': '일년 중 가장 멋진 시간을 향한 카운트다운이 모두에게 적합한 강림절 달력과 함께 시작되었습니다.',

  '_chr._tt1': '크리스마스 내내 쇼핑하세요',
  '_chr._tt2': '크리스마스 장식',
  '_chr._tt3': '모든 선물 쇼핑',
  '_chr._tt4': '크리스마스 잠옷 모두 쇼핑하기',

  '_chr._dt1': '크리스마스를 앞두고는 중요한 날만큼이나 마법적입니다. 그러니 선물을 정리하고, 홀을 꾸미고, 크리스마스의 마법에 어울리는 잠옷을 준비하세요.',

  '_chr._dtt1': '크리스마스 의류',
  '_chr._dtt2': '가족의 크리스마스와 어울리는',
  '_chr._dtt3': '강림절 달력',
  '_chr._dtt4': '집에서의 크리스마스',
  '_chr._dtt5': '아늑한 가게',
  '_chr._dtt6': '크리스마스를 위한 가구',

  '_chr._tre1': '지금 인기 급상승 중',
  '_chr._tre2': '모두가 좋아하는 트렌드로 집에 축제의 즐거움을 더해보세요.',
  '_chr._tre3': '진저브레드',
  '_chr._tre4': '크리스마스 푸딩',
  '_chr._tre5': '크리스마스 공크스',

  '_chr._ttre1': '카드 및 포장지',
  '_chr._ttre2': '장난감 및 게임',
  '_chr._ttre3': '음식 및 음료 선물',
  '_chr._ttre4': '스타킹 필러',
  '_chr._ttre5': '비밀 산타',
  '_chr._ttre6': '아기의 첫 번째 크리스마스',
  '_chr._ttre7': '미용 선물',
  '_chr._ttre8': '프리미엄 선물',
  '_chr._ttre9': '맞춤형 선물',

  '_sdj._sdnz': '여성 의류',
  '_sdj._sdn': '남성 의류',
  '_sdj._sdetlw': '선물',
  '_sdj._sdzs': '장식',
  '_sdj._sdjj': '장난감',

  '_sdj._title1': '크리스마스가 다가오고 있습니다',
  '_sdj._desc1': '"크리스마스: 나눔과 감사의 계절." 올 12월, 여러분을 위해 밝게 장식되고 따뜻한 가족 파티를 준비했습니다! 밝고 따뜻한 다양한 제품을 대폭 할인된 가격으로 만나보세요! ',

  '_ssd._lhpp': '유연한 결제',
  '_ssd._lzhq': '소스 글로벌',
  '_ssd._pwyx': '매우 우수함',
  '_ssd._qsth': '쉬운 반품',
  '_ssd._jgppcn': '가격 매칭 및 약속',
  '_ssd._ztbt': '글로벌 원스톱 쇼핑 만들기',
  '_ssd._yzspl': '8,000개가 넘는 독립 브랜드로부터 직접 재고 소싱',
  '_ssd._zcgm': '구매하려면 등록하세요',
  '_ssd._zccs': '판매 등록',

  '_ssd._dlwz': '로그인 및 등록',

  '_ssd._qjrgwc': '장바구니에 모두 추가',

  '_zddy._gzlb': '관심 목록',
  '_zddy._zsersxs': 'shopifyoutlets에서 판매됨',
  '_zddy._zccwsj': '판매자로 등록',
  '_zddy._dlyh': '로그인',
  '_zddy._zcyh': '등록',
  '_zddy._xyh': '새 사용자',
  '_zddy._gwc': '장바구니',
  '_zddy._znxx': '메시지',
  '_zddy._zshy': '가장 인기 있는',
  '_zddy._ddl': '주문',
  '_zddy._mysj': '데이터 없음',
  '_zddy._ckgd': '더 보기',
  '_zddy._tjsp': '추천 제품',
  '_zddy._rmsp': '인기 제품, 새로운 트렌드, 더 많은 구매 기회',
  '_zddy._xsp': '새 제품',
  '_zddy._jlzzr': '지난 2주 동안 가장 인기 있었던 항목',
  '_zddy._ljgm': '지금 구매',
  '_zddy._gdsp': '더 많은 인기 제품',
  '_zddy._ssbt': '검색',
  '_zddy._ssgjz': '나는 ..을(를) 찾고 있습니다.',

  '_zddy._bdhb': '현지 통화',
  '_zddy._xzbdhb': '현지 통화 선택',
  '_zddy._zje': '총액',
  '_zddy._xzzffs': '자신에게 맞는 결제 방법을 선택하세요',
  '_zddy._jmhb': '암호화폐',

  '_zddy._cp': '제품',
  '_zddy._sj': '사업가',
  '_zddy._mj': '판매자',
  '_zddy._fhsy': '홈페이지로 돌아가기',

  '_sjsy._ddpl': '주문 목록',
  '_yhqd._czbz': '충전 전 충전정보 확인을 위해 전용 고객센터로 문의하시기 바랍니다.',
  '_yhqd._lxkf': '고객 서비스에 문의하세요',
  '_yhqd._qsrje': '충전금액을 입력해주세요',
  '_yhqd._wrhyhtd': '은행 접근 불가',
  '_yhqd._yhzz': '은행 송금',
  '_yhbt._yhlx': '은행 유형',
  '_zcyz._sjdxyz': '모바일 SMS 확인',
  '_zcyz._sjhm': '전화 번호',
  '_zcyz._srsjhm': '휴대폰 번호를 입력하세요',
  '_zzsd._qsrzzje': '이체금액을 입력해주세요',
  '_zzsd._zzje': '이체 금액',
  '_txcd._qsrtxje': '출금금액을 입력해주세요',
  '_txcd._skr': '수취인',
  '_txcd._skr._ms': '카드 소유자 이름을 입력하세요.',
  '_txcd._skzh': '결제계좌번호',
  '_txcd._skzh._ms': '결제계좌번호를 입력해주세요',
  '_txcd._tjyhk': '은행 카드 추가',
  '_txcd._txje': '출금금액',
  '_txcd._wdyhdz': '계좌 번호',
  '_txcd._yhdm': '암호',
  '_txcd._yhdm._ms': '은행코드를 입력해주세요',
  '_txcd._yhdz': '주소',
  '_txcd._yhdz._ms': '은행 주소를 입력해주세요',
  '_txcd._yhlx': '유형',
  '_txcd._yhlx._ms': '은행 카드 유형을 입력하세요.',
  '_txcd._yhly': '라우팅',
  '_txcd._yhly._ms': '은행 라우팅을 입력하세요.',
  '_txcd._yhmc': '은행',
  '_txcd._yhmc._ms': '은행명을 입력해주세요',
  '_txcd._yhtx': '은행현금인출',

  '_jys._gmjmhb': '암호화폐 구매',
  '_jys._gmzy': '다음 거래소에 가서 현재 국가의 정책에 따라 계정을 등록하고 암호화폐를 구입할 수 있습니다. 그럼 플랫폼 계정으로 충전해보세요! ',

  '_kdydj._bt': '상점을 열고 큰 상품을 받으세요',
  '_kdydj._xyzbt': '행운은 계속 변합니다',
  '_kdydj._yx': '{n}번 펌핑',
  '_kdydj._xh': '소비 {n} 포인트',

  '_kdydj._jf': '점수:',
  '_kdydj._zjjl': '우승 기록',

  '_kdydj._hdgz': '활동 규칙',
  '_kdydj._qx': '제발',
  '_kdydj._dl': '로그인',

  '_kdydj._mjdl': '판매자 로그인',
  '_kdydj._yxh': '이메일',
  '_kdydj._yxhsm': '이메일을 입력하세요',
  '_kdydj._yxhmm': '비밀번호를 입력하세요',
  '_kdydj._rgmyzh': '계정이 없는 경우',
  '_kdydj._djzc': '등록하려면 클릭하세요',
  '_kdydj._wjmm': '비밀번호 찾기',

  '_kdydj._wdjp': '내 상품',
  '_kdydj._zjts': '승리 팁',
  '_kdydj._jx': '계속',
  '_kdydj._czl': '승리',

  '_kdydj._ljjl': '누적 보상',
  '_kdydj._ycy': '참여: {n}회',
  '_kdydj._cy': '{n}회 참여',
  '_kdydj._lq': '수신',
  '_kdydj._ylq': '수신됨',
  '_kdydj._wdd': '도달하지 못함',

  '_dk._dkbt1': '창업대출은 돈을 빌리는 고민을 해결해줍니다',
  '_dk._dkbt2': '기업 대출 및 자본 순환 서비스 제공',
  '_dk._dkbt3': '자금이 부족하거나, 돈을 빌릴 수 없는 상황, 친구에게 돈을 빌려달라고 하기가 민망한 상황 등의 대출 문제를 해결하는 데 도움을 드립니다. 목표는 모든 대출을 투명하게 만들어 고객이 빠르고 안심하고 사용할 수 있도록 하는 것입니다. ',
  '_dk._zxsq': '온라인 지원',
  '_dk._wddk': '내 대출',
  '_dk._aqwy': '보안 및 걱정 없음',
  '_dk._lhed': '유연한 할당량',
  '_dk._lhedb': '금액: 5,00 ~ 500,000, 낮은 이자, 유연한 상환 방법, 선불 수수료 없음, 온라인 대출, 검토 전화 없음, 정보 기밀 유지 및 당일 대출. ',
  '_dk._jsfk': '빠른 대출',
  '_dk._jsfkb': '최단 1시간 내에 심사를 통과하며, 심사 후 즉시 결제가 이루어집니다. ',
  '_dk._aqww1': '안전하고 걱정이 없습니다',
  '_dk._aqww1b': '담보대출도 없고 보증금도 필요하지 않으며 전화 회신도 필요하지 않습니다. 이는 사적인 문제입니다. ',

  '_dk._gywm': '회사 소개',
  '_dk._gywm1': '우리는 "고객 우선"이라는 경영 철학과 고객의 자본 회전 문제 해결이라는 사명을 바탕으로 대출 금융 서비스에 최선을 다하고 있습니다. 회사는 창립 이래 수많은 고객의 자본 회전 문제를 해결해 왔습니다. 문제. ',
  '_dk._gywm2': '금액: 500~500,000, 낮은 이자, 유연한 상환 방법, 선불 수수료 없음, 온라인 대출, 검토 전화 없음, 정보 기밀 유지 및 당일 대출. ',
  '_dk._gywm3': '동창이나 친구에게 돈을 빌리고 싶지 않다면 저희에게 오세요. ',
  '_dk._gywm4': '기업가 대출 서비스에 전념',
  '_dk._gywm5': '재정 문제를 해결하세요',
  '_dk._hzhb': '파트너 - 온라인 대출 플랫폼',
  '_dk._smwda': 'shopifyoutlets Business 글로벌 스테이션의 사용자는 전 세계 112개국에서 왔으며 결제를 위해 USDT/ETH/BTC/TRX 암호화폐를 사용합니다. 암호화폐는 국경 없는 거래 방식으로, 대기 시간이나 국제 수수료 없이 전 세계에서 즉시 저비용 거래가 가능합니다. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 All Rights Reserved',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited(0008945)는 금융행위감독청(Financial Conduct Authority)의 신용 중개인으로 승인 및 규제되며 대출 기관인 Home Retail Group CardServices Limited와 독점적으로 협력하여 shopifyoutlets Business Financial Services, shopifyoutlets Business Financial로 거래됩니다. 서비스는 신용 제공을 위해 금융행위감독청(Financial Conduct Authority)에 의해 승인되고 규제됩니다. Home Retail GroupCard Services Limited는 영국(04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, United Kingdom에 등록되어 있습니다. MK9 2NW. ',

  '_dk._cyzjsq': '기업 대출 신청서 작성',
  '_dk._dkll': '대출 이자율',
  '_dk._dkll2': '대출 이자율 설명 (표시하려면 클릭)',
  '_dk._gs1': '수식',
  '_dk._gs2': '관심사',
  '_dk._gs3': '대출 금액',
  '_dk._gs4': '연간 이자율',
  '_dk._gs5': '대출 일수',
  '_dk._gs6': '예를 들어',
  '_dk._gs7': '대출 금액',
  '_dk._gs8': '대출 주기',
  '_dk._gs9': '일',
  '_dk._fkfs': '대출 방법',
  '_dk._zhye': '계정 잔액',
  '_dk._dkqx': '대출 기간',
  '_dk._dkqx1': '대출 기간 선택',
  '_dk._sqje': '신청 금액',
  '_dk._qsrdk': '대출을 입력해주세요',
  '_dk._dkje': '대출 금액',
  '_dk._gj': '국적',
  '_dk._qxzgj': '국적을 선택하세요',
  '_dk._sqr': '지원자',
  '_dk._qsrsqsm': '지원자의 이름을 입력하세요',
  '_dk._zjhm': 'ID 번호',
  '_dk._qsrzjhm': 'ID 번호를 입력하세요',
  '_dk._zjzp': '신분증 사진',
  '_dk._zjzp1': '신분증 앞면 사진을 업로드해주세요',
  '_dk._zjfm1': '신분증 뒷면 사진을 업로드해 주세요.',
  '_dk._zjsc1': '신분증 사진을 업로드해주세요',
  '_dk._zjzp2': '사진 선택',
  '_dk._pzslt': '사진 예',
  '_dk._wytyb': '읽었고 동의합니다.',
  '_dk._jkxy': '대출 계약',
  '_dk._tjsq': '신청서 제출',

  '_dk._wddk2': '내 대출',
  '_dk._ydk': '대출됨',
  '_dk._cslx': '관심 유발',
  '_dk._yjhk': '예상 상환액',
  '_dk._dkjl': '대출 기록',
  '_dk._hkjl': '상환 기록',
  '_dk._sqrq': '신청 날짜',
  '_dk._hkzq': '상환 주기',
  '_dk._gdll': '고정 이자율',
  '_dk._hkz': '상환 진행 중',
  '_dk._yhk': '상환',
  '_dk._sqz': '적용 중',
  '_dk._ytg': '통과',
  '_dk._ybh': '거부됨',
  '_dk._tqhk': '조기 상환',

  '_dk._ljhk': '즉시 상환',
  '_dk._qrytqhk': '정말로 미리 상환을 신청하시겠습니까?',
  '_dk._qrytqhk1': '참고: 신청이 성공한 후 입금된 금액은 우선적으로 대출금으로 직접 반환됩니다.',
  '_dk._dkxq': '대출 세부정보',
  '_dk._dkje1': '대출 금액',
  '_dk._yhlx': '이자 지불 기한',
  '_dk._hkze': '총액 상환',
  '_dk._sqhkz': '상환 신청 중',
  '_dk._zxydbty': '"대출 계약"에 동의하려면 주의 깊게 읽고 확인란을 선택하세요.',
  '_dk._zzsqz': '적용 중',

  '_dk._dksq': '신용',

  '_dk._cs': '매개변수',
  '_dk._nllsm': '백분율로 표현됩니다. 예: 6.25%',
  '_dk._dktssm': '대출 승인 시작부터 끝까지의 총 일수',
  '_dk._yndts': '1년의 일수: 일반적으로 365일',
  '_dk._zlj': '연체료',

  '_xbb._xz': '다운로드',
  '_xbb._xzapp': 'ShopifyOutlets 앱 다운로드',
  '_xbb._smewm': '다운로드하려면 QR 코드를 스캔하세요',
  '_xbb._syfl': '모든 카테고리',
  '_xbb._gdfl': '더 보기',
  '_xbb._sbbt1': '빠른 배송',
  '_xbb._sbbt2': '문제가 있을 경우 환불 가능',
  '_xbb._sbbt3': '무료 반품',
  '_xbb._sbbt4': '90일 이내',
  '_xbb._tjsp1': '추천 제품',
  '_xbb._tjsp2': '많은 할인',
  '_xbb._tjsp3': '한정 할인',

  '_xbb._asjgw': '상점별 쇼핑',
  '_xbb._gdsp': '더 많은 제품',
  '_xbb._ljgm': '지금 구매',
  '_xbb._ptbz': 'ShopifyOutlets 보증',
  '_xbb._aqzf': '안전한 결제',
  '_xbb._aqzf1': '많은 해외 ​​쇼핑객이 사용하는 결제 방법',
  '_xbb._aqys': '보안 및 개인 정보 보호',
  '_xbb._aqys1': '우리는 귀하의 개인정보를 존중하므로 귀하의 개인정보는 안전합니다.',
  '_xbb._mjbh': '구매자 보호',
  '_xbb._mjbh1': '주문이 예상 날짜 이전에 배송되지 않거나 주문이 만족스럽지 않은 경우 환불을 받을 수 있습니다.',
  '_xbb._mzsm': '경고/면책조항',

  '_xbb._sjxx': '사업가',
  '_xbb._ggms': '사양',
  '_xbb._mswa': '설명',
  '_xbb._khpl': '고객 의견',
  '_xbb._zmfk': '긍정적인 피드백',
  '_xbb._gzrs': '팔로우',
  '_xbb._gzrsan': '팔로우',
  '_xbb._ygzrsan': '이미 팔로우됨',
  '_xbb._fsxx': '메시지',
  '_xbb._myrpl': '아직 댓글이 없습니다',
  '_xbb._llgd': '더 보기',
  '_xbb._pdcl': '약속',
  '_xbb._myf': '무료 배송',
  '_xbb._sdsj': '약 5~10분 안에 배송됨',
  '_xbb._ksjh': '빠른 배송',
  '_xbb._bgdstk': '분실된 패키지에 대한 환불',
  '_xbb._shbgtk': '항목이 손상된 경우 환불하세요',
  '_xbb._s30gtk': '30일 이내에 배송되지 않으면 환불',
  '_xbb._aqyys': '보안 및 개인 정보 보호',
  '_xbb._wjtrxk': '안전한 결제: 당사는 귀하의 동의 없이 귀하의 개인정보를 제3자와 공유하지 않습니다. ',
  '_xbb._sfgrxx': '개인 정보 보호: 우리는 귀하의 개인 정보를 보호하고 개인 정보를 안전하게 보호합니다. ',
  '_xbb._sl': '수량',
  '_xbb._kcld': '인벤토리',

  '_xbb._sjrz': '상인이 정착했습니다',
  '_xbb._hezjh': '저희 파트너 프로그램은 귀하에게 광범위한 마케팅 지원 및 서비스를 제공하며, 당사 고객 서비스 팀은 귀하의 제품 디스플레이 및 판매 전략을 최적화하는 데 도움이 되는 전문적인 지원과 조언을 제공할 것입니다. 지금 참여하세요! 더 큰 비즈니스 기회를 창출하고 함께 성장합시다! ',
  '_xbb._xsje': '비즈니스 정보',
  '_xbb._rgsqjs': '이미 판매자인 경우',
  '_xbb._qni': '제발',
  '_xbb._djdl': '로그인하려면 클릭하세요',
  '_xbb._dplog': '로고 저장',
  '_xbb._dpmc': '상점 이름',
  '_xbb._srdpmc': '상점 이름을 입력하세요',
  '_xbb._zjhm': 'ID 번호',
  '_xbb._srzjhm': 'ID 번호를 입력하세요',
  '_xbb._zsxm': '실명',
  '_xbb._srzsxm': '실명을 입력하세요',
  '_xbb._srgj': '국가',
  '_xbb._srsrgj': '국가를 입력하세요',
  '_xbb._zjz': '신분증 사진',
  '_xbb._zjzm': '문서 앞부분',
  '_xbb._zjfm': '인증서 뒷면',
  '_xbb._sczjz': '신분증 사진 보관',
  '_xbb._pssl': '사진 예',
  '_xbb._dlyx': '로그인 이메일',
  '_xbb._srdlyx': '로그인 이메일을 입력하세요',
  '_xbb._dlmm': '로그인 비밀번호',
  '_xbb._srdlmm': '로그인 비밀번호를 입력하세요',
  '_xbb._qrmm': '비밀번호 확인',
  '_xbb._srqrmm': '확인 비밀번호를 입력하세요',
  '_xbb._yqm': '초대 코드',
  '_xbb._sryqm': '초대 코드를 입력하세요',
  '_xbb._yxyzm': '이메일 확인 코드',
  '_xbb._sryzm': '이메일 확인 코드를 입력하세요',
  '_xbb._yydbty': '읽고 동의했습니다.',
  '_xbb._rzxy': '화해 합의'
}
