export default {
  'nav.account': 'Centrul personal',
  'nav.language': 'Chineză simplificată',
  'signup.success': 'Înscriere reușită',

  //eroare
  'contact.empty': 'Informațiile de contact nu pot fi goale',
  'phone.country.empty': 'Prefixul telefonului nu poate fi gol',
  'phone.empty': 'Telefonul nu poate fi gol',
  'email.valid': 'Vă rugăm să introduceți adresa de e-mail corectă',

  // baza
  'base.success': 'succes',
  'base.save': 'Salvare',
  'base.slide': 'Trageți cursorul pentru a verifica',
  'base.send.code': 'Trimite codul de verificare',
  'base.send.time': '({time}) retrimite codul de verificare',
  'base.send.success': 'Trimite cu succes',
  'base.view.more': 'Vedeți mai multe',
  'base.Language': 'Language',
  'base.previous': 'Pagina anterioară',
  'base.next': 'Pagina următoare',
  'base.total.page': 'Total {page} pagini',
  'base.go.to.page': 'Mergeți la pagină',
  'base.go': 'mergi la',
  'base.followers': 'Uderitori',
  'base.follow': 'urmare',
  'base.following': 'Urmat',
  'base.visit.store': 'Răsfoiește magazinul',
  'base.contact': 'Contactați-ne',
  'base.delivery': 'Timp de livrare estimat: 1-7 zile',
  'base.search': 'Căutare',
  'base.search.text': 'Introduceți căutarea prin cuvinte cheie',
  'base.server': 'Serviciul clienți online',

  //index
  'text.categories.title': 'Categoria de produs',
  'text.welcome': 'Bine ați venit la cumpărături!',
  'text.wishlist': 'Lista de aprecieri',
  'text.account': 'Centrul personal',
  'text.orders': 'Comenzi',
  'text.messages': 'Mesaje',
  'text.suggest.title': 'Ofertă exclusivă',
  'text.suggest.content': 'Numai pentru noii membri premium globale!',
  'text.super.deals': 'Produse de top, prețuri incredibile.',

  //Inscrie-te
  'signup.register': 'Înregistrare',
  'signup.signin': 'Autentificare',
  'signup.store.signin': 'Autentificare la afaceri',
  'signup.sign.out': 'Deconectare',
  'signup.email': 'Adresă de e-mail',
  'signup.password': 'Parola',
  'signup.qr.password': 'Confirmați parola',
  'signup.confirm.password': 'Confirmați parola',
  'signup.forgot.password': 'Am uitat parola',
  'signup.invitation': 'Cod de invitație',
  'signup.char': 'Personaj',
  'signup.contains': 'Conține numere, litere sau simboluri',
  'signup.qr.contains': 'Cele două parole sunt inconsecvente',
  'signup.create.account': 'Creează cont',
  'signup.agree': 'Creează un cont, ești de acord',
  'signup.member.agreement': 'Acord de membru',
  'signup.and': 'și',
  'signup.privacy.policy': 'Politica de confidențialitate',
  'signup.email.code': 'Verificare e-mail',
  'signup.last.step': 'Ultimul pas',
  'signup.send.email': 'Vă rugăm să introduceți codul din 4 cifre trimis către {email}',
  'signup.modify.email': 'Modifică adresa de e-mail',
  'signup.verify.email': 'Verificați adresa de e-mail',
  'signup.have.store': 'Aveți cont de comerciant',
  'signup.goto.signin': 'Autentificare la afaceri',
  'signup.no.store': 'Fără cont de comerciant',
  'signup.goto.store': 'Înscrieți-vă comerciant',
  'signup.next': 'Următorul',
  'signup.your.email': 'e-mailul dumneavoastră',
  'signup.code.text': 'Cod de verificare',
  'signup.submit.signup': 'Înscrie-te acum',
  'signup.smrz': 'Autentificare cu nume real',
  'signup.derb': 'Al doilea pas',
  'signup.qsrxm': 'Vă rugăm să introduceți numele documentului',
  'signup.qsrhm': 'Vă rugăm să introduceți numărul de identificare',

  //uitat
  'forgot.title': 'Resetează parola',
  'forgot.btn.check': 'Verificați adresa de e-mail',
  'forgot.reset.now': 'Resetează acum',

  //magazin
  'store.info.open': 'Deschis {y} săptămâni',

  // subsol
  'footer.great.value': 'O mare valoare',
  'footer.great.value.desc': 'Oferim prețuri competitive pentru peste 100 de milioane de articole.',
  'footer.shopping': 'Cumpărături globale',
  'footer.shopping.desc': 'Livram în peste 200 de țări și regiuni, iar site-ul nostru web este disponibil în 7 limbi.',
  'footer.safe.payment': 'Plată sigură',
  'footer.safe.payment.desc': 'Plătiți cu cele mai populare și mai sigure metode de plată din lume.',
  'footer.shop.with.confidence': 'Cumpărați cu încredere',
  'footer.shop.with.confidence.desc': 'Politica noastră de protecție a cumpărătorilor acoperă întregul proces de achiziție',
  'footer.help.center': 'Centrul de ajutor',
  'footer.help.center.desc': 'Asistență pe orice vreme pentru a crea o experiență de cumpărături fără probleme.',
  'footer.terms.conditions': 'Termeni și condiții',
  'footer.return.policy': 'Politica de returnare',
  'footer.support.policy': 'Politica de asistenţă',
  'footer.privacy.policy': 'Politica de confidențialitate',
  'footer.be.seller': 'Deveniți vânzător',
  'footer.apply.now': 'Aplicați acum',
  'footer.stay.connected': 'Rămâneți conectat',

  'footer.about.us': 'Despre noi',
  'footer.about.company': 'Profil de companie',
  'footer.about.video': 'Prezentare video',
  'footer.contact': 'Informații de contact',

  'footer.my.account': 'Contul meu',
  'footer.my.logout': 'Deconectare',
  'footer.my.order': 'Istoricul comenzilor',
  'footer.my.wish': 'Lista mea de dorințe',
  'footer.my.join': 'Deveniți partener membru',
  'footer.email': 'E-mail',
  'footer.gfemail': 'E-mail oficial',
  'footer.fwemail': 'E-mail de serviciu',
  'footer.address': 'Adresă',

  'apply.success': 'Aplicație reușită',
  'apply.success.desc': 'Aplicat cu succes, accesați contul de conectare',

  // categorii
  'category.title': 'Categorii înrudite',
  'category.all': 'Toate categoriile',

  //detaliu
  'detail.store.home': 'Magazin de pornire',
  'detail.sale.items': 'Lista de produse',
  'detail.recommend': 'Recomanda',
  'detail.orders': 'Vânzări',
  'detail.quantity': 'Cantitate',
  'detail.pieces.available': 'Disponibil',
  'detail.delivery': 'Livrare',
  'detail.free.shipping': 'Livrare gratuită',
  'detail.estimated.delivery': 'Sosire estimată',
  'detail.days': 'zile',
  'detail.buy.now': 'Cumpără',
  'detail.add.to.cart': 'Adaugă în coș',
  'detail.buyer.protection': 'Protecția cumpărătorului',
  'detail.money.guarantee': 'Garanție de returnare a banilor',
  'detail.refund.desc': 'Obțineți o rambursare completă dacă articolul nu este conform descrierii sau nu este livrat',
  'detail.description': 'Descrierea produsului',
  'detail.customer.reviews': 'Recenzii clienți',
  'detail.specifications': 'Specificații de produs',
  'detail.top.selling.products': 'Produse cele mai vândute',
  'detail.recommended.for.you': 'Recomandat pentru tine',
  'detail.sold': 'Vânzări',
  'detail.receipt': 'Confirmați primirea',
  'detail.receipt.title': 'Confirmați primirea comenzii? ',
  'detail.receipt.content': 'După confirmare, comanda tranzacției este finalizată',
  'detail.comment': 'Comentariu',
  'detail.refund.title': 'Confirmați cererea de rambursare',
  'detail.refund.content': 'După confirmare, comanda va solicita o rambursare',
  'detail.sqtk': 'Solicitați rambursarea',

  //a confirma
  'confirm.shipping.address': 'Adresa destinatarului',
  'confirm.change': 'Schimbare',
  'confirm.payment.methods': 'Metode de plată',
  'confirm.summary': 'Decontare',
  'confirm.total.item.costs': 'Total articole',
  'confirm.total': 'Total',
  'confirm.checkout': 'Mergeți la checkout',
  'confirm.place.order': 'Plasați o comandă acum',
  'confirm.pay.now': 'Plătește acum',
  'confirm.order.desc': 'După ce am făcut clic pe "Plasează comandă", confirm că am citit și confirm',
  'confirm.order.policy': 'Toți termenii și politicile',
  'confirm.payment': 'Global Shopping vă asigură securitatea informațiilor și a plăților',
  // abordare
  'address.title': 'Adresa de primire',
  'address.default': 'implicit',
  'address.edit': 'Editează',
  'address.delete': 'Șterge',
  'address.new': 'Creează o nouă adresă',
  'address.contact': 'Contact',
  'address.address': 'Adresă',
  'address.phone': 'Număr de telefon',
  'address.set.default': 'Setați implicit',
  'address.confirm': 'Confirmați',
  'address.cancel': 'Anulare',
  'address.del.title': 'Confirmați pentru a șterge adresa?',
  'address.del.content': 'Sunteți sigur că doriți să ștergeți această adresă de expediere?',

  'payment.method': 'Metoda de plată',

  // Cărucior de cumpărături
  'shopping.title': 'Coș de cumpărături',
  'shopping.back.buy': 'Înapoi la cumpărături speciale',
  'shopping.start.shopping': 'Începeți cumpărăturile',

  // membru
  'member.account': 'Centrul personal',
  'member.overview': 'Prezentare generală',
  'member.orders': 'Comenzi',
  'member.payment': 'Portofelul meu',
  'member.address': 'Adresa de primire',
  'member.wishlist': 'Lista de aprecieri',
  'member.followlist': 'Urmărește lista',
  'member.message.center': 'Centrul de mesaje',
  'member.setting': 'Setări personale',
  'member.shop.info': 'Informații despre magazin',
  'member.shop.setting': 'Setări magazin',
  'member.order.notify': 'Notificare de comandă nouă',
  'member.order.tips': 'Prin trimiterea de e-mailuri către e-mailul contului comerciantului',

  // membru.ordine
  'member.order.title': 'Comanda mea',
  'member.order.view.all': 'Vedeți toate',
  'member.order.all': 'Toate',
  'member.order.unpaid': 'neplătit',
  'member.order.paid': 'Plătit',
  'member.order.procurement': 'de cumpărat',
  'member.order.seller.paid': 'Achiziționat',
  'member.order.processing': 'de procesat',
  'member.order.shipped': 'expediat',
  'member.order.return': 'Se întoarce',
  'member.order.completed': 'Finalizat',
  'member.order.refund': 'Rambursat',
  'member.order.all.time': 'toate',
  'member.order.empty': 'Încă nu există comandă',
  'member.order.date': 'data',
  'member.order.purchase.date': 'Cumpărare',
  'member.order.cpoy': 'Copiere',
  'member.order.id': 'Comanda',
  'member.order.detail': 'Detalii comandă',
  'member.order.logistics': 'Informații de logistică',

  // membru.plata
  'member.payment.title': 'Portofelul meu',
  'member.wallet.balance': 'sold portofel',
  'member.crypto.recharge': 'Reîncărcare',
  'member.crypto.withdrawal': 'Retragere',
  'member.crypto.bank': 'Card bancar',
  'member.wallet.record': 'înregistrarea depozitului de monede',
  'member.bankcard.record': 'Înregistrare card bancar',
  'member.withdrawal.record': 'Înregistrare de retragere',
  'member.income.record': 'înregistrarea veniturilor',
  'member.transaction.record': 'Înregistrarea tranzacției',
  'member.wallet.freeze': 'Înghețați fondurile',
  'member.wallet.profit': 'Câștiguri estimate',

  //REÎNCĂRCĂ
  'recharge.currency': 'Moneda',
  'recharge.protocol': 'Protocol',
  'recharge.qrcode': 'Cod QR',
  'recharge.address': 'Adresă portofel',
  'recharge.copy': 'Copiați adresa',
  'recharge.ok.text': 'Confirmați',
  'recharge.cancel.text': 'Anulează',

  //bancă
  'bank.recharge.title': 'Reîncărcare card bancar',
  'bank.title': 'Numele băncii',
  'bank.name': 'nume',
  'bank.account': 'Cont',
  'bank.routing': 'Routing',
  'bank.code': 'cod',
  'bank.bankname': 'Numele băncii',
  'bank.bankaddress': 'Adresa băncii',
  'bank.ok.text': 'Următorul',
  'bank.ok.prev': 'Pasul anterior',
  'bank.submit': 'Trimiteți acum',
  'bank.amount': 'Suma de reîncărcare',
  'bank.amount.desc': 'Vă rugăm să introduceți suma de reîncărcare',
  'bank.type': 'tip',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Numărul chitanței',
  'bank.receipt.number.desc': 'Vă rugăm să introduceți numărul chitanței tranzacției',
  'bank.credential.picture': 'Imagine de acreditare',
  'bank.credential.picture.desc': 'Vă rugăm să încărcați poza de autentificare',
  'bank.remark': 'Remarcă',
  'bank.upload': 'Încărcați imagini',
  'bank.text.title': 'Note',
  'bank.text.t1': 'Nu aveți nevoie de observații pentru transfer, lăsați-l necompletat',
  'bank.text.t2': 'Selectați Hong Kong ca țară a gospodăriei din Hong Kong, nu adăugați China',
  'bank.text.t3': 'Remitențe din SUA, în zilele lucrătoare, remitențe către Europa înainte de ora 15:00, remitențele sunt disponibile în timpul programului de lucru al băncii!',
  'bank.text.t4': 'Timpul de plată este T+1, iar cea mai recentă plată este T+3 în Europa',
  'bank.text.t5': 'Informați platforma înainte de a transfera bani pentru a confirma dacă contul este disponibil. Dacă contul este închis, nu se va plăti nicio compensație.',
  'bank.text.t6': 'Depuneți prin card bancar, toate informațiile despre serviciul clienți vor prevala.',

  //listă bancară
  'bank.list.title': 'Numele băncii',
  'bank.list.code': 'Cod bancar',
  'bank.list.amount': 'Suma de transfer',
  'bank.list.number': 'Număr chitanță',
  'bank.list.img': 'Imaginea voucherului',
  'bank.list.status': 'Stare',
  'bank.list.time': 'ora',
  'bank.list.status1': 'În curs de examinare',
  'bank.list.status2': 'Aproape',
  'bank.list.status3': 'Respins',

  // Retragere
  'withdrawal.address.desc': 'Vă rugăm să introduceți adresa portofelului!',
  'withdrawal.number': 'Număr',
  'withdrawal.real.number': 'sosire reală',
  'withdrawal.number.desc': 'Vă rugăm să introduceți suma retragerii!',
  'withdrawal.btn.all': 'toate',
  'withdrawal.balance': 'Sold',
  'withdrawal.commission': 'comision',
  'withdrawal.actual.amount': 'Contabilizat',
  'withdrawal.notice': 'Prompt',
  'withdrawal.notice.text': 'Înainte de transfer, vă rugăm să confirmați că informațiile despre adresa de primire sunt corecte. Odată ce activele sunt transferate, acestea nu pot fi returnate.',
  'withdrawal.notice.content': '{name}({cp_name}) comision: valoarea de piață actuală {fee_rate}%/pen, standard minim: {fee_min} {name}/pen',
  'withdrawal.submit': 'Trimite',
  'withdrawal.choice': 'Alegeți o criptomonedă',
  'withdrawal.yzm': 'Cod de verificare',
  'withdrawal.fs': 'Trimite',
  'withdrawal.qsryzm': 'Vă rugăm să introduceți codul de verificare a e-mailului',

  // recive
  'recive.method': 'Protocol',
  'recive.amount': 'Suma',
  'recive.address': 'Adresă',
  'recive.date': 'ora',
  'recive.status': 'Stare',
  'recive.create.at': 'Timp tranzacție',
  'recive.type': 'Tip',
  'recive.befor': 'înainte de tranzacție',
  'recive.balance': 'Sold',
  'recive.freeze': 'Înghețare',
  'recive.review': 'Revizuire',
  'recive.success': 'Succes',
  'recive.reject': 'Respins',

  // face reclamă
  'advertise.title': 'Centrul de promovare',
  'advertise.shop.title': 'Promoția magazinului',
  'advertise.shop.status': 'Stare',
  'advertise.shop.expired': 'Expirat',
  'advertise.shop.promotion': 'Promovare',
  'advertise.shop.expire.date': 'Data de expirare',
  'advertise.shop.renew': 'Reînnoirea reclamei',
  'advertise.shop.payable': 'Suma de plătit',
  'advertise.shop.explanation': 'Explicația reclamei',
  'advertise.shop.text': 'Participă la promovarea publicității pe platformă, crește expunerea magazinului și promovează comenzile de tranzacții',
  'advertise.shop.paynow': 'Reînnoiește acum',
  'advertise.shop.modal.title': 'Confirmați plata',
  'advertise.shop.modal.desc': 'Confirmat pentru plata taxei de promovare',
  'advertise.shop.modal.btn': 'Confirmați plata',

  //Lista de dorințe
  'wishlist.title': 'Wishlist',
  'wishlist.delete': 'Șterge',
  'wishlist.orders': 'Vânzări',

  //Urmărește Lista
  'followlist.title': 'Urmărește Lista',
  'followlist.delete': 'Șterge',
  'followlist.follow': 'Urmărire',

  // magazin
  'store.dashboard': 'Tabloul de bord',
  'store.products': 'Produse',
  'store.products.list': 'Lista de produse',
  'store.products.reviews': 'Recenzii despre produse',
  'store.orders': 'Comenzi',
  'store.wallet': 'Portofel',
  'store.message': 'Centrul de mesaje',
  'store.setting': 'setare',
  'store.order.total.profit': 'Venit total din exploatare estimat',

  //bord
  'dashboard.store.hour.views': 'Vizite în timp real',
  'dashboard.store.today.views': 'Vizitele de astăzi',
  'dashboard.product.total': 'Produs total',
  'dashboard.product.today': 'Nou astăzi',
  'dashboard.order.total': 'Comanda totală',
  'dashboard.sales.total': 'Cifra de afaceri estimată',
  'dashboard.sales.real': 'Vânzări reale',
  'dashboard.sales.pay': 'Plătire totală',
  'dashboard.sales.profit': 'Profit total',
  'dashboard.commission.total': 'Venit total',
  'dashboard.commission.today': 'Câștiguri de astăzi',
  'dashboard.order.sales': 'Vânzări de mărfuri',
  'dashboard.sales.list': 'Clasarea vânzărilor',
  'dashboard.goods.cate.rate': 'Raport de clasificare a produselor',
  'dashboard.goods.wish': 'Clasarea produselor ca produse',
  'dashboard.january': 'ianuarie',
  'dashboard.february': 'februarie',
  'dashboard.march': 'Martie',
  'dashboard.april': 'aprilie',
  'dashboard.may': 'Mai',
  'dashboard.june': 'iunie',
  'dashboard.july': 'iulie',
  'dashboard.august': 'August',
  'dashboard.september': 'septembrie',
  'dashboard.october': 'Octombrie',
  'dashboard.november': 'noiembrie',
  'dashboard.december': 'Decembrie',

  // produse
  'products.add.new': 'Adăugați un produs nou',
  'products.add.from.warehouse': 'Adăugați produse din depozit',
  'products.delete': 'Șterge',
  'products.add': 'Adăugați',
  'products.table.img': 'imagine',
  'products.table.name': 'nume produs',
  'products.table.category': 'Categorie',
  'products.table.wish': 'like',
  'products.table.stock': 'stoc',
  'products.table.price': 'Preț',
  'products.table.profit': 'Profit',
  'products.table.action': 'Acțiune',
  'products.search.category': 'Căutare după categorie',
  'products.back.product': 'Înapoi la lista de produse',
  'products.total': 'Total bunuri',
  'products.yes': 'Da',
  'products.no': 'Anulează',
  'products.batch.add': 'Creșterea lotului',
  'products.ask.add': 'Sunteți sigur că doriți să adăugați produse?',
  'products.batch.delete': 'Ștergerea lotului',
  'products.ask.delete': 'Sunteți sigur că doriți să ștergeți produsul?',
  'products.top': 'Sus',
  'products.syzd': 'Începutul paginii de pornire',
  'products.zdwz': 'Poziția de sus',
  'products.t1': 'Promoție',
  'products.t2': 'De preferat',
  'products.t3': 'Boutique',
  'products.t4': 'niciunul',
  // recenzii
  'reviews.title': 'Recenzii despre produse',
  'reviews.product.name': 'Produs',
  'reviews.user.name': 'Utilizator',
  'reviews.star': 'Evaluare',
  'reviews.comment': 'Comentează conținutul',
  'reviews.sku': 'Specificațiile produsului',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Data',

  // magazin.comandă
  'store.order.purchase': 'Cumpără acum',
  'store.order.purchase.desc': 'Sunteți sigur că doriți să plătiți pentru acest produs?',
  'store.order.purchase.yes': 'Plătiți acum',
  'store.order.purchase.no': 'Anulează',
  'store.order.desc': 'Achiziționarea acestei comenzi necesită plată și veți obține beneficii după finalizarea comenzii. ',
  'store.order.no': 'Număr comandă',
  'store.order.number': 'Cantitate',
  'store.order.buyer': 'Cumparator',
  'store.order.total': 'Suma totală',
  'store.order.will.earning': 'Venit',
  'store.order.profit': 'Profit',
  'store.order.dividends': 'Dividende',
  'store.order.payment.status': 'Starea plății',
  'store.order.seller.buy.status': 'Starea achiziției',
  'store.order.status': 'Starea comenzii',
  'store.order.date': 'data comenzii',
  'store.order.purchase.date': 'Data achiziției',
  'store.order.action': 'Operare',
  'store.order.purchase.amount': 'Suma achiziției',

  //sursa de venit
  'income.create.at': 'Timp de înregistrare',
  'income.order.sn': 'Numărul comenzii',
  'income.realpay': 'Suma comandă',
  'income.profit': 'venit',

  //Setare
  'setting.avatar': 'Avatar de afaceri',
  'setting.upload': 'Încărcare',
  'setting.shop.name': 'Numele magazinului',
  'setting.shop.phone': 'Telefon de contact',
  'setting.shop.address': 'Adresa magazinului',
  'setting.shop.save': 'Salvare',
  'setting.upload.pic': 'Încarcă imaginea',
  'setting.send.pic': 'Trimite poza',

  //beseller
  'beseller.title': 'Solicitați pentru a fi comerciant',
  'beseller.account': 'Informații despre cont',
  'beseller.store': 'Informații magazin',
  'beseller.store.name': 'Numele magazinului',
  'beseller.store.address': 'Adresa magazinului',

  //salvează acasă
  'store.home.title': 'Setări pentru pagina de pornire',
  'store.home.topimg': 'imaginea de sus',
  'store.home.banner': 'Imagine carusel',
  'store.home.up3': 'Observații: cel puțin 3 încărcări',
  'store.upload.more': 'Încărcați mai multe imagini',
  'store.home.columns': 'Coloane',
  'store.home.bgimg': 'Imagine de fundal',
  'store.goods.remark': 'Observație: 5 articole pe rând, cel mult 10 articole pe farfurie',
  'store.home.select': 'Selectați produsul',
  'store.home.add': 'Adăugați coloană',

  //Informații personale
  'setting.update.user': 'Informații personale',
  'setting.update.info': 'Modificați informații',
  'setting.user.avatar': 'Avatar utilizator',
  'setting.user.nickname': 'Pseudonim utilizator',
  'setting.user.nickname.desc': 'Vă rugăm să introduceți porecla utilizatorului',

  'setting.safe.title': 'Informații de siguranță',
  'setting.user.passwd': 'Schimbați parola',
  'setting.passwd.title1': 'Parolă veche',
  'setting.passwd.desc1': 'Vă rugăm să introduceți vechea parolă',
  'setting.passwd.title2': 'Parolă nouă',
  'setting.passwd.desc2': 'Vă rugăm să introduceți o nouă parolă',
  'setting.passwd.title3': 'Confirmați parola',
  'setting.passwd.desc3': 'Vă rugăm să introduceți o parolă de confirmare',
  'setting.invitation': 'Invitați prieteni',
  'setting.copy': 'Copiere',

  'adv.add.products': 'Adăugați produse promoționale',
  'adv.list.title': 'Promovarea produsului',
  'adv.type': 'Tipul de promovare',
  'adv.begin.time': 'Timp de promovare',
  'adv.end.time': 'Ora de încheiere',
  'adv.status': 'Stare',
  'adv.status.s1': 'Promovare',
  'adv.status.s2': 'Sfârșit',
  'adv.add.back': 'Reveniți la lista promovată',
  'adv.recharge': 'Reîncărcare puncte de promovare',
  'adv.select.goods': 'Selectați bunuri',
  'adv.select.btn': 'Produse promovate',
  'adv.recharge.title': 'Reîncărcare puncte de promovare',
  'adv.recharge.balance': 'Sold de puncte',
  'adv.point': 'punct',
  'adv.point.rate': 'Raport de puncte',
  'adv.recharge.amount': 'Suma de reîncărcare',
  'adv.recharge.input.amount': 'Introduceți suma de reîncărcare',
  'adv.select.mod': 'Selectați panoul',
  'adv.mod1': 'Coloane recomandate',
  'adv.mod2': 'Coloană preferată',
  'adv.mod3': 'Coloană cu vânzări foarte bune',
  'adv.mod4': 'Lista excelentă',
  'adv.mod5': 'Lista de clasificare',
  'adv.mod6': 'Promoție detaliată',
  'adv.ge': 'Cantitate',
  'adv.xs': 'ora',
  'adv.xzbk': 'Selectați farfuria',
  'adv.syme': 'Cota rămasă',
  'adv.tgsc': 'Durata promoției',
  'adv.tgjg': 'Prețul promoției',
  'adv.syye': 'Sold actual',
  'adv.tgye': 'Schimul promoției',
  'adv.yj1': 'așteptată',
  'adv.yj2': 'Începe promovarea',
  'adv.pay.point': 'Puncte de consum',

  'wallet.ykcsxf': 'Taxa de gestionare a fost dedusă',
  'wallet.sxf': 'Taxa de serviciu',
  'wallet.zxje': 'Suma minimă',
  'wallet.24hxe': 'limită de 24 de ore',
  'wallet.desc.text': 'În nicio circumstanță, oficialul nu vă va cere să transferați bani într-un cont și nici nu vă va cere un cod de verificare. Nu participați la activități ilegale, cum ar fi cumpărarea în numele altora, spălarea banilor și strângerea ilegală de fonduri și ferește-te de frauda online',
  'wallet.cunbi.text': 'Puteți depune doar {name} la această adresă, alte active nu vor fi recuperate',

  'credit.title': 'Limita de credit',
  'credit.ed': 'Cotă',
  'credit.hk': 'Rambursare',
  'credit.kyye': 'Sold disponibil',
  'credit.dqqk': 'Arierate curente',
  'credit.hkje': 'Suma de rambursare',
  'credit.hkje.desc': 'Vă rugăm să introduceți suma de rambursare',
  'credit.cgje': 'Suma de achiziție',
  'credit.qrz': 'Mergeți la autentificare',
  'credit.srxyj': 'Introduceți suma plății creditului',

  'store.gzl': 'Suma de atenție',
  'store.wxz': 'Nelimitat',

  'auth.smrz': 'Autentificare cu nume real',
  'auth.rzlx': 'Tip',
  'auth.sfzm': 'Fața cărții de identitate',
  'auth.sffm': 'Reversul cărții de identitate',
  'auth.zsxm': 'nume real',
  'auth.zjhm': 'Numărul documentului',
  'auth.yyzz': 'Licență de afaceri',
  'auth.gsmc': 'Numele companiei',
  'auth.shz': 'Audit',
  'auth.ytg': 'Aproape',
  'auth.ybh': 'Respins',
  'auth.zt': 'stare',
  'auth.gr': 'Personal',
  'auth.gs': 'Întreprindere',
  'auth.ljtj': 'Solicitați autentificare',
  'auth.wrz': 'Neautentificat',

  'credit.qts': 'Vedeți descrierea',
  'store.spss': 'Limita superioară a mărfurilor',
  'store.dpfh': 'Dividendele stocate',
  'store.qydj': 'Nivelul capitalului propriu',
  'store.level': 'nivel',
  'store.jian': 'bucăți',

  'order.notify.email': 'Datorită controlului riscului prin e-mail, e-mailurile de memento pentru comenzile platformei vor fi interceptate frecvent. Pentru a le primi la timp, se recomandă să adăugați {e} la lista albă de e-mail de conectare',

  'setting.sjxx': 'Informații despre companie',
  'setting.smrz': 'Autentificare cu nume real',
  'setting.aqzx': 'Centrul de securitate',
  'setting.tzxx': 'Informații de notificare',
  'setting.dpzx': 'Pagina principală de decorare',
  'setting.sjqy': 'Interese de afaceri',
  'setting.sjdjqy': 'Descrierea beneficiilor la nivel de afaceri',

  'setting.fhbky': 'Dividendul actual nu este disponibil',
  'setting.spgzyzy': 'Regulile și regulile magazinului',

  'desc.dqqy': 'Capitalul curent',
  'desc.xjqy': 'Interese subordonate',
  'desc.sjqy_0': 'Limita de credit pentru stocare',
  'desc.sjqy_1': 'Storezi dividendele',
  'desc.sjqy_2': 'Numărul de produse care pot fi publicate',

  'share.yqlb': 'Lista de invitații',
  'share.zmj': 'Vânzător total',
  'share.zdd': 'Comanda totală',
  'share.zsy': 'Venit total din brokeraj',
  'share.wcl': 'neprocesat',
  'share.ycl': 'Procesat',
  'share.ywc': 'Finalizat',
  'share.dpmc': 'Numele magazinului',
  'share.ddl': 'Cantitatea comenzii',
  'share.jjsy': 'Venit din brokeraj',
  'share.zts': 'Număr total',

  'chat.state': 'stat',
  'chat.zx': 'Online',
  'chat.ljz': 'Se conectează',

  'bind.wallet.title': 'Adresă de retragere',
  'bind.address.title': 'Adăugați adresa',
  'bind.bj': 'Editează',
  'bind.sc': 'Șterge',
  'bind.qrsc': 'Sunteți sigur că doriți să ștergeți această adresă?',
  'bind.qbdz': 'Adresă portofel',
  'bind.tjsj': 'Adăugați ora',
  'bind.cz': 'Operare',
  'withdrawal.address.choice': 'Vă rugăm să alegeți adresa de retragere!',

  'order.sxsj': 'Timp efectiv',
  'order.ljsj': 'eficient imediat',
  'order.dssj': 'Efectuos cronometrat',

  'store.st.zt': 'Starea magazinului',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Magazinul dvs. este normal, vă rugăm să continuați așa',
  'store.st.yc': 'Excepție',
  'store.st.yc.desc': 'Magazinul dvs. are unele comenzi anormale sau au primit reclamații ale clienților',
  'store.st.dj': 'Înghețați',
  'store.st.dj.desc': 'Magazinul dumneavoastră are un anumit risc de funcționare și fondurile sunt înghețate',
  'store.st.jy': 'dezactivat',
  'store.st.jy.desc': 'Magazinul dvs. a încălcat acordurile și regulile legate de platformă și a fost dezactivat',

  'task.sy': 'Pagina de pornire',
  'task.rwlbo': 'Lista de sarcini',
  'task.rwxqo': 'Detaliile sarcinii',
  'task.ljcy': 'Participă acum',
  'task.gdrw': 'Mai multe sarcini',
  'task.rwlb': 'Lista sarcinilor de preluare',
  'task.qbrw': 'Toate sarcinile',
  'task.wcyd': 'Am participat la',
  'task.qdz': 'Primirea comenzilor',
  'task.ywc': 'Finalizat',
  'task.djy': 'de tranzacționat',
  'task.ddje': 'Suma comandă',
  'task.cyrs': 'Număr de participanți',
  'task.ewsy': 'Avantaje suplimentare',
  'task.jzsj': 'Termen limită',
  'task.ckxq': 'Vedeți detalii',
  'task.rwxq': 'Detalii activitate',
  'task.cylb': 'Lista de participare',
  'task.qdcyrw': 'Sunteți sigur că doriți să participați la această sarcină de comandă?',
  'task.sd': 'Da',
  'task.bue': 'nu',

  'task.wdsy': 'Venituri',
  'task.wdfh': 'Dividende',
  'task.wdqd': 'Obțineți comanda',
  'task.dd.leixin': 'Tipul de comandă',
  'task.dd.qdrw': 'Sarcina de comandă rapidă',
  'task.dd.ptdd': 'Ordine obișnuită',

  'flow.lltg': 'Promovarea fluxului',
  'flow.gmll': 'Achiziționați pachet de date',
  'flow.dqtc': 'Pachetul actual',
  'flow.tc1': 'Pachet',
  'flow.sy2': 'remaining',
  'flow.tian3': 'zi',
  'flow.jlzs': 'Numărul total de înregistrări',
  'flow.lltc': 'Pachet flux',
  'flow.ll': 'flux',
  'flow.gmsj': 'Timp de cumpărare',
  'flow.tcjg': 'Prețul pachetului',
  'flow.sjzf': 'plată reală',
  'flow.tcsm': 'Descrierea pachetului',
  'flow.tcsm.sm': 'Achiziționarea acestui pachet va crește expunerea magazinului și va câștiga mai mult trafic de clienți. Pentru a obține mai multe comenzi și a crește veniturile din magazin. ',

  'flow.tcmc': 'Numele pachetului',
  'flow.gmsc': 'Durata achiziției',
  'flow.zje': 'Suma totală',
  'flow.jzsj': 'Termen limită',
  'flow.gmrq': 'Data achiziției',
  'flow.tian1': 'zi',
  'flow.zhou1': 'săptămână',
  'flow.yue1': 'lună',

  'share.dj': 'nivel',

  'share.hz': 'Transfer',
  'share.hzd': 'Transfer la',
  'share.qbye': 'sold portofel',
  'share.tgje': 'Suma promoției',
  'share.hzje': 'Suma de transfer',
  'share.qsrhzje': 'Vă rugăm să introduceți suma de transfer',

  '_inv._title': 'Descrierea invitației',
  '_inv._t1': '1. Invitație și relație invitată',
  '_inv._desc1': 'A îl invită pe B, B îl invită pe C, C îl invită pe D. Cel mai înalt nivel este 4, dacă D îl invită din nou pe E, atunci E nu are nimic de-a face cu A. ',
  '_inv._t2': '2. Invitatorul primește o recompensă',
  '_inv._desc2': 'A primește 4% din suma totală finalizată la comanda B, A primește 2% din suma totală finalizată la comanda C și A primește 1% din suma totală finalizată la comanda D',

  '_index._gm': 'Cumpărare',
  '_index._sm': 'Descriere: Creșteți expunerea magazinului și atrageți trafic',

  '_shop._update': '{n} ori/lună, modificat de {m} ori în luna curentă',

  '_chat._hc': 'Retragere',

  '_footer._suom': 'Descrierea politicii',

  '_footer._platform._qualification': 'Calificarea companiei',
  '_store._ptzz': 'Calificări legate de platforma Magazinului următor',

  '_abt._gywm': 'Despre noi',
  '_abt._syms': 'Model de afaceri',
  '_abt._qyzr': 'Responsabilitatea corporativă',
  '_abt._lxwm': 'Contactează-ne',

  '_wsj._wsjms': 'Sărbătorește Halloween-ul cu stil în magazinul nostru înfricoșător de Halloween. Fie că cauți un truc sau un răsfăț în acest sezon înfricoșător, colecția noastră de costume, decorațiuni și accesorii de Halloween are ceva pentru întreaga familie. o declarație înfiorătoare cu colecțiile noastre pentru femei și bărbați sau dezlănțuiți imaginația copilului dvs. cu costume de lux. De la articole și jucării drăguțe de neuitat, la frumusețe și cosmetice pline de farmec, găsiți piesele perfecte pentru a sărbători cea mai incitantă sărbătoare a anului.',

  '_wsj._b1': 'Decor de toamnă',
  '_wsj._b2': 'Dovleacul',
  '_wsj._b3': 'Acasă',
  '_wsj._b4': 'Copii',
  '_wsj._b5': 'De la bebeluși',
  '_wsj._b6': 'Pentru femei',
  '_wsj._b7': 'Rochie de lux',
  '_wsj._b8': 'Personalizat',

  '_wsj._cj': 'Participă',
  '_wsj._gz': 'Reguli de activitate',
  '_wsj._flss': 'Căutare categorie',
  '_wsj._wsnz': 'Îmbrăcăminte pentru femei de Halloween',
  '_wsj._wsn': 'Îmbrăcăminte pentru bărbați de Halloween',
  '_wsj._wst': 'Îmbrăcăminte pentru copii de Halloween',
  '_wsj._wszs': 'Decor de Halloween',
  '_wsj._wslp': 'Cadou de Halloween',

  '_wsj._desc1': '"Special Halloween, Trick or Treat!" În luna octombrie, ți-am pregătit o petrecere de carnaval! Vino și iei tot felul de produse înfricoșătoare și șocante, te așteaptă reduceri mari! ',
  '_wsj._lkm': 'Mergeți la cumpărături',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween se apropie',
  '_wsj._mzbt': 'Calendare de advent pentru frumusețe',
  '_wsj._mznr': 'Numărătoarea inversă până la cea mai minunată perioadă a anului tocmai a aterizat cu calendare de advent care să se potrivească tuturor.',

  '_chr._tt1': 'Cumpara tot Craciunul',
  '_chr._tt2': 'Decoratiuni de Crăciun',
  '_chr._tt3': 'Cumpără toate cadourile',
  '_chr._tt4': 'Cumpărați toate hainele de noapte de Crăciun',

  '_chr._dt1': 'Pregătirea Crăciunului este la fel de magică ca și ziua cea mare. Așa că aranjați-vă cadourile, aranjați holurile și pregătiți-vă pijamalele asortate pentru magia Crăciunului.',

  '_chr._dtt1': 'Haine de Crăciun',
  '_chr._dtt2': 'Se potrivește Crăciunul de familie',
  '_chr._dtt3': 'Calendare de Advent',
  '_chr._dtt4': 'Crăciun acasă',
  '_chr._dtt5': 'Magazin confortabil',
  '_chr._dtt6': 'Mobilier la timp pentru Crăciun',

  '_chr._tre1': 'La tendințe acum',
  '_chr._tre2': 'Adăugați un pic de distracție festivă în casa dvs. cu tendințele pe care toată lumea le iubește.',
  '_chr._tre3': 'Turtă dulce',
  '_chr._tre4': 'Budincă de Crăciun',
  '_chr._tre5': 'Gonks de Crăciun',

  '_chr._ttre1': 'Card și hârtie de împachetat',
  '_chr._ttre2': 'Jucării și jocuri',
  '_chr._ttre3': 'Cadouri cu mâncare și băutură',
  '_chr._ttre4': 'Umpluturi de stocare',
  '_chr._ttre5': 'Moș Crăciun Secret',
  '_chr._ttre6': 'Primul Crăciun al bebelușului',
  '_chr._ttre7': 'Cadouri de frumusețe',
  '_chr._ttre8': 'Cadouri premium',
  '_chr._ttre9': 'Cadouri personalizate',

  '_sdj._sdnz': 'Haine pentru femei',
  '_sdj._sdn': 'Haine pentru bărbați',
  '_sdj._sdetlw': 'cadou',
  '_sdj._sdzs': 'Decorare',
  '_sdj._sdjj': 'jucărie',

  '_sdj._title1': 'Se apropie Crăciunul',
  '_sdj._desc1': '"Crăciun: anotimpul dăruirii și mulțumirii." În luna decembrie, ți-am pregătit o petrecere de familie luminoasă și caldă! Veniți și luați o varietate de produse luminoase și calde, cu reduceri mari care vă așteaptă! ',

  '_ssd._lhpp': 'Plată flexibilă',
  '_ssd._lzhq': 'Sursă globală',
  '_ssd._pwyx': 'evaluat ca excelent',
  '_ssd._qsth': 'Return ușor',
  '_ssd._jgppcn': 'Potrivirea prețului și angajament',
  '_ssd._ztbt': 'Creează un ghișeu global global',
  '_ssd._yzspl': 'Aprovizionarea inventarului direct de la peste 8.000 de mărci independente',
  '_ssd._zcgm': 'Înregistrați-vă pentru a cumpăra',
  '_ssd._zccs': 'Înregistrați-vă pentru vânzare',

  '_ssd._dlwz': 'Autentificare și înregistrare',

  '_ssd._qjrgwc': 'Adăugați totul în coș',

  '_zddy._gzlb': 'Lista de urmărire',
  '_zddy._zsersxs': 'Vândut la shopifyoutlets',
  '_zddy._zccwsj': 'Înregistrați-vă ca comerciant',
  '_zddy._dlyh': 'Autentificare',
  '_zddy._zcyh': 'Înregistrează-te',
  '_zddy._xyh': 'Utilizator nou',
  '_zddy._gwc': 'Coș de cumpărături',
  '_zddy._znxx': 'Mesaj',
  '_zddy._zshy': 'Cel mai popular',
  '_zddy._ddl': 'Comanda',
  '_zddy._mysj': 'Fără date',
  '_zddy._ckgd': 'Vezi mai multe',
  '_zddy._tjsp': 'Produse recomandate',
  '_zddy._rmsp': 'Produse populare, noi tendințe, mai multe oportunități de cumpărare',
  '_zddy._xsp': 'Produs nou',
  '_zddy._jlzzr': 'Cele mai tare articole din ultimele două săptămâni',
  '_zddy._ljgm': 'Cumpără acum',
  '_zddy._gdsp': 'Produse mai populare',
  '_zddy._ssbt': 'Căutare',
  '_zddy._ssgjz': 'Caut...',

  '_zddy._bdhb': 'Moneda locală',
  '_zddy._xzbdhb': 'Selectați moneda locală',
  '_zddy._zje': 'Suma totală',
  '_zddy._xzzffs': 'Vă rugăm să alegeți metoda de plată care vi se potrivește',
  '_zddy._jmhb': 'Criptomonedă',

  '_zddy._cp': 'produs',
  '_zddy._sj': 'Om de afaceri',
  '_zddy._mj': 'Vânzător',
  '_zddy._fhsy': 'Reveniți la pagina de pornire',

  '_sjsy._ddpl': 'Lista de comenzi',
  '_yhqd._czbz': 'Vă rugăm să contactați serviciul pentru clienți dedicat pentru a confirma informațiile de reîncărcare înainte de reîncărcare.',
  '_yhqd._lxkf': 'Contactați Serviciul Clienți',
  '_yhqd._qsrje': 'Vă rugăm să introduceți suma de reîncărcare',
  '_yhqd._wrhyhtd': 'Fără acces bancar',
  '_yhqd._yhzz': 'Transfer bancar',
  '_yhbt._yhlx': 'Tip banca',
  '_zcyz._sjdxyz': 'Verificare prin SMS mobil',
  '_zcyz._sjhm': 'număr de telefon',
  '_zcyz._srsjhm': 'Introduceți numărul de mobil',
  '_zzsd._qsrzzje': 'Vă rugăm să introduceți suma transferului',
  '_zzsd._zzje': 'suma de transfer',
  '_txcd._qsrtxje': 'Vă rugăm să introduceți suma de retragere',
  '_txcd._skr': 'Beneficiar',
  '_txcd._skr._ms': 'Vă rugăm să introduceți numele titularului cardului',
  '_txcd._skzh': 'Numărul contului de plată',
  '_txcd._skzh._ms': 'Vă rugăm să introduceți numărul contului de plată',
  '_txcd._tjyhk': 'Adăugați card bancar',
  '_txcd._txje': 'Suma de retragere',
  '_txcd._wdyhdz': 'adresa băncii',
  '_txcd._yhdm': 'cod',
  '_txcd._yhdm._ms': 'Vă rugăm să introduceți codul bancar',
  '_txcd._yhdz': 'abordare',
  '_txcd._yhdz._ms': 'Vă rugăm să introduceți adresa băncii',
  '_txcd._yhlx': 'tip',
  '_txcd._yhlx._ms': 'Vă rugăm să introduceți tipul cardului bancar',
  '_txcd._yhly': 'rutare',
  '_txcd._yhly._ms': 'Vă rugăm să introduceți ruta bancară',
  '_txcd._yhmc': 'bancă',
  '_txcd._yhmc._ms': 'Vă rugăm să introduceți numele băncii',
  '_txcd._yhtx': 'Retragere de numerar bancar',

  '_jys._gmjmhb': 'Cumpără criptomonede',
  '_jys._gmzy': 'Puteți să vă înregistrați un cont și să cumpărați criptomonede mergând la următoarele burse și în conformitate cu politicile țării dvs. actuale. Apoi reîncărcați în contul platformei! ',

  '_kdydj._bt': 'Deschide un magazin și câștigă premii mari',
  '_kdydj._xyzbt': 'Norocul continuă să se întoarcă',
  '_kdydj._yx': 'Pompează de {n} ori',
  '_kdydj._xh': 'Consum {n} puncte',

  '_kdydj._jf': 'Puncte:',
  '_kdydj._zjjl': 'Record câștigător',

  '_kdydj._hdgz': 'Reguli de activitate',
  '_kdydj._qx': 'Vă rog',
  '_kdydj._dl': 'Autentificare',

  '_kdydj._mjdl': 'Autentificare vânzător',
  '_kdydj._yxh': 'E-mail',
  '_kdydj._yxhsm': 'Vă rugăm să introduceți adresa de e-mail',
  '_kdydj._yxhmm': 'Vă rugăm să introduceți parola',
  '_kdydj._rgmyzh': 'Dacă nu aveți un cont',
  '_kdydj._djzc': 'Faceți clic pentru a vă înregistra',
  '_kdydj._wjmm': 'Am uitat parola',

  '_kdydj._wdjp': 'Premiul meu',
  '_kdydj._zjts': 'Sfaturi pentru câștiguri',
  '_kdydj._jx': 'Continuați',
  '_kdydj._czl': 'Câștigat',

  '_kdydj._ljjl': 'Recompensă cumulativă',
  '_kdydj._ycy': 'Participat: de {n} ori',
  '_kdydj._cy': 'Participă de {n} ori',
  '_kdydj._lq': 'Primiți',
  '_kdydj._ylq': 'Primit',
  '_kdydj._wdd': 'Nu a fost atins',

  '_dk._dkbt1': 'Împrumutul pentru antreprenoriat rezolvă problema de a împrumuta bani',
  '_dk._dkbt2': 'Oferă împrumuturi antreprenoriale și servicii de circulație a capitalului',
  '_dk._dkbt3': 'Vă vom ajuta să rezolvați problemele legate de împrumuturi, cum ar fi fondurile strânse, unde să împrumutați bani și să vă fie jenat să le cereți prietenilor să împrumute. Oferim servicii profesionale pe tot parcursul procesului și vă răspundem oricând Scopul este ca fiecare împrumut să fie transparent, permițând clienților să le folosească rapid și cu încredere. ',
  '_dk._zxsq': 'Aplicație online',
  '_dk._wddk': 'Împrumutul meu',
  '_dk._aqwy': 'Securitate și fără griji',
  '_dk._lhed': 'Cotă flexibilă',
  '_dk._lhedb': 'Suma: 5,00 până la 500,000, dobândă scăzută, metode de rambursare flexibile, fără comisioane în avans, împrumut online, fără apeluri de revizuire, confidențialitatea informațiilor și împrumut în aceeași zi. ',
  '_dk._jsfk': 'Împrumut rapid',
  '_dk._jsfkb': 'Treceți revizuirea în cea mai rapidă oră, iar plata se va face imediat după examinare. ',
  '_dk._aqww1': 'Sigur și fără griji',
  '_dk._aqww1b': 'Fără împrumut ipotecar, fără depozit, fără apeluri returnate, aceasta este o chestiune privată. ',

  '_dk._gywm': 'Despre noi',
  '_dk._gywm1': "Ne angajăm să împrumutăm servicii financiare, bazate pe filozofia de afaceri a 'clientului în primul rând' și cu misiunea de a rezolva problemele de rulare a capitalului clienților De la înființare, compania a ajutat nenumărați clienți să rezolve cifra de afaceri Probleme. ",
  '_dk._gywm2': 'Suma: 500 până la 500.000, dobândă scăzută, metode flexibile de rambursare, fără comisioane inițiale, împrumut online, fără apeluri de revizuire, confidențialitatea informațiilor și împrumut în aceeași zi. ',
  '_dk._gywm3': 'Dacă nu doriți să împrumutați bani de la colegi și prieteni, vă rugăm să veniți la noi. ',
  '_dk._gywm4': 'Angajamentul față de serviciile de împrumut pentru antreprenori',
  '_dk._gywm5': 'Rezolvați-vă problemele financiare',
  '_dk._hzhb': 'Partener - Platformă de împrumut online',
  '_dk._smwda': 'Utilizatorii stației globale shopifyoutlets Business provin din 112 țări din întreaga lume și folosesc criptomoneda USDT/ETH/BTC/TRX pentru decontare. Criptomoneda este o modalitate fără frontiere de tranzacționare, permițând tranzacții instantanee la preț redus în întreaga lume, fără așteptări și fără taxe internaționale. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Toate drepturile rezervate',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) este autorizată și reglementată de Financial Conduct Authority ca broker de credit și lucrează exclusiv cu creditorul Home Retail Group CardServices Limited, tranzacționând ca shopifyoutlets Business Financial Services , shopifyoutlets Business Financial Serviciile sunt autorizate și reglementate de Autoritatea de Conduită Financiară pentru a oferi credit. Home Retail GroupCard Services Limited este înregistrată în Anglia (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Regatul Unit. MK9 2NW. ',

  '_dk._cyzjsq': 'Completați cererea de împrumut pentru afaceri',
  '_dk._dkll': 'Rata dobânzii la împrumut',
  '_dk._dkll2': 'Descrierea ratei dobânzii la împrumut (dați clic pentru afișare)',
  '_dk._gs1': 'Formulă',
  '_dk._gs2': 'Interes',
  '_dk._gs3': 'Suma împrumutului',
  '_dk._gs4': 'Rata anuală a dobânzii',
  '_dk._gs5': 'Număr de zile de împrumut',
  '_dk._gs6': 'de exemplu',
  '_dk._gs7': 'Suma împrumutului',
  '_dk._gs8': 'Ciclul de împrumut',
  '_dk._gs9': 'zi',
  '_dk._fkfs': 'Metoda de creditare',
  '_dk._zhye': 'Soldul contului',
  '_dk._dkqx': 'Perioada de împrumut',
  '_dk._dkqx1': 'Selectați perioada de împrumut',
  '_dk._sqje': 'Suma aplicației',
  '_dk._qsrdk': 'Vă rugăm să introduceți împrumut',
  '_dk._dkje': 'Suma împrumutului',
  '_dk._gj': 'Naționalitate',
  '_dk._qxzgj': 'Vă rugăm să selectați naționalitatea',
  '_dk._sqr': 'Solicitant',
  '_dk._qsrsqsm': 'Vă rugăm să introduceți numele solicitantului',
  '_dk._zjhm': 'Număr ID',
  '_dk._qsrzjhm': 'Vă rugăm să introduceți numărul de identificare',
  '_dk._zjzp': 'Fotografie de identitate',
  '_dk._zjzp1': 'Vă rugăm să încărcați o fotografie cu partea din față a actului dvs. de identitate',
  '_dk._zjfm1': 'Vă rugăm să încărcați o fotografie cu reversul actului dvs. de identitate',
  '_dk._zjsc1': 'Vă rugăm să încărcați o fotografie a cărții dvs. de identitate',
  '_dk._zjzp2': 'Selectează imaginea',
  '_dk._pzslt': 'Exemplu de fotografie',
  '_dk._wytyb': 'Am citit și sunt de acord',
  '_dk._jkxy': 'Acord de împrumut',
  '_dk._tjsq': 'Trimiteți cererea',

  '_dk._wddk2': 'Împrumutul meu',
  '_dk._ydk': 'Împrumutat',
  '_dk._cslx': 'Generează dobândă',
  '_dk._yjhk': 'Rambursare estimată',
  '_dk._dkjl': 'Înregistrare împrumut',
  '_dk._hkjl': 'Înregistrare de rambursare',
  '_dk._sqrq': 'Data cererii',
  '_dk._hkzq': 'Ciclul de rambursare',
  '_dk._gdll': 'Rata fixă ​​a dobânzii',
  '_dk._hkz': 'Rambursare în curs',
  '_dk._yhk': 'Rambursat',
  '_dk._sqz': 'Se aplică',
  '_dk._ytg': 'Aproape',
  '_dk._ybh': 'Respins',
  '_dk._tqhk': 'Rambursare anticipată',

  '_dk._ljhk': 'Rambursează imediat',
  '_dk._qrytqhk': 'Sunteți sigur că doriți să solicitați rambursarea în avans?',
  '_dk._qrytqhk1': 'Notă: După ce cererea are succes, suma depusă va fi returnată direct împrumutului în mod prioritar',
  '_dk._dkxq': 'Detalii despre împrumut',
  '_dk._dkje1': 'Suma împrumutului',
  '_dk._yhlx': 'Dobândă datorată',
  '_dk._hkze': 'Rambursare totală',
  '_dk._sqhkz': 'Solicitarea rambursării',
  '_dk._zxydbty': "Vă rugăm să citiți cu atenție și să bifați caseta pentru a fi de acord cu 'Contractul de împrumut'",
  '_dk._zzsqz': 'Se aplică',

  '_dk._dksq': 'Credit',

  '_dk._cs': 'Parametri',
  '_dk._nllsm': 'Exprimat ca procent, de exemplu: 6,25%',
  '_dk._dktssm': 'Numărul total de zile de la începutul până la sfârșitul aprobării împrumutului',
  '_dk._yndts': 'Numărul de zile dintr-un an: de obicei 365 de zile',
  '_dk._zlj': 'Taxă de întârziere',

  '_xbb._xz': 'Descărcați',
  '_xbb._xzapp': 'Descărcați aplicația ShopifyOutlets',
  '_xbb._smewm': 'Scanează codul QR pentru descărcare',
  '_xbb._syfl': 'Toate categoriile',
  '_xbb._gdfl': 'Mai multe',
  '_xbb._sbbt1': 'Livrare rapidă',
  '_xbb._sbbt2': 'Rambursările sunt disponibile dacă există probleme',
  '_xbb._sbbt3': 'Retururi gratuite',
  '_xbb._sbbt4': 'În 90 de zile',
  '_xbb._tjsp1': 'Produse recomandate',
  '_xbb._tjsp2': 'Multe reduceri',
  '_xbb._tjsp3': 'Reducere pentru timp limitat',

  '_xbb._asjgw': 'Cumpărături după magazin',
  '_xbb._gdsp': 'Mai multe produse',
  '_xbb._ljgm': 'Cumpără acum',
  '_xbb._ptbz': 'Garanție ShopifyOutlets',
  '_xbb._aqzf': 'Plată sigură',
  '_xbb._aqzf1': 'O metodă de plată folosită de mulți cumpărători internaționali',
  '_xbb._aqys': 'Securitate și confidențialitate',
  '_xbb._aqys1': 'Vă respectăm confidențialitatea, astfel încât informațiile dumneavoastră personale sunt în siguranță',
  '_xbb._mjbh': 'Protecția cumpărătorului',
  '_xbb._mjbh1': 'Dacă comanda dvs. nu este livrată înainte de data estimată sau nu sunteți mulțumit de comandă, puteți obține o rambursare',
  '_xbb._mzsm': 'Avertisment/Declinare a răspunderii',

  '_xbb._sjxx': 'Om de afaceri',
  '_xbb._ggms': 'Specificație',
  '_xbb._mswa': 'Descriere',
  '_xbb._khpl': 'Comentariile clienților',
  '_xbb._zmfk': 'Feedback pozitiv',
  '_xbb._gzrs': 'Urmărire',
  '_xbb._gzrsan': 'Urmărire',
  '_xbb._ygzrsan': 'Urmărit deja',
  '_xbb._fsxx': 'Mesaj',
  '_xbb._myrpl': 'Fără comentarii încă',
  '_xbb._llgd': 'Vedeți mai multe',
  '_xbb._pdcl': 'Promit',
  '_xbb._myf': 'Livrare gratuită',
  '_xbb._sdsj': 'Livrat în aproximativ 5-10',
  '_xbb._ksjh': 'Livrare rapidă',
  '_xbb._bgdstk': 'Rambursare pentru pachetul pierdut',
  '_xbb._shbgtk': 'Dacă articolul este deteriorat, rambursați',
  '_xbb._s30gtk': 'Rambursări dacă nu sunt expediate în 30 de zile',
  '_xbb._aqyys': 'Securitate și confidențialitate',
  '_xbb._wjtrxk': 'Plată sigură: nu vom împărtăși informațiile dvs. personale cu niciun terț fără consimțământul dumneavoastră. ',
  '_xbb._sfgrxx': 'Detaliile personale securizate: vă protejăm confidențialitatea și vă păstrăm datele personale în siguranță. ',
  '_xbb._sl': 'Cantitate',
  '_xbb._kcld': 'Inventar',

  '_xbb._sjrz': 'Comerciantul s-a instalat',
  '_xbb._hezjh': 'Programul nostru de parteneri vă oferă o gamă completă de asistență și servicii de marketing, iar echipa noastră de servicii pentru clienți vă va oferi asistență și consiliere profesională pentru a vă ajuta să vă optimizați strategia de afișare a produselor și de vânzare. Alăturați-vă nouă acum! Să creăm oportunități de afaceri mai mari și să creștem împreună! ',
  '_xbb._xsje': 'Informații despre companie',
  '_xbb._rgsqjs': 'Dacă sunteți deja vânzător',
  '_xbb._qni': 'Vă rog',
  '_xbb._djdl': 'Faceţi clic pentru a vă conecta',
  '_xbb._dplog': 'Store LOGO',
  '_xbb._dpmc': 'Numele magazinului',
  '_xbb._srdpmc': 'Vă rugăm să introduceți numele magazinului',
  '_xbb._zjhm': 'Număr ID',
  '_xbb._srzjhm': 'Vă rugăm să introduceți numărul de identificare',
  '_xbb._zsxm': 'nume real',
  '_xbb._srzsxm': 'Vă rugăm să introduceți numele dvs. real',
  '_xbb._srgj': 'Țară',
  '_xbb._srsrgj': 'Vă rugăm să introduceți țara',
  '_xbb._zjz': 'Fotografie de identitate',
  '_xbb._zjzm': 'Fața documentului',
  '_xbb._zjfm': 'Reversul certificatului',
  '_xbb._sczjz': 'Păstrează fotografia de identitate',
  '_xbb._pssl': 'Exemplu de fotografie',
  '_xbb._dlyx': 'E-mail de conectare',
  '_xbb._srdlyx': 'Vă rugăm să introduceți adresa de e-mail de conectare',
  '_xbb._dlmm': 'Parola de conectare',
  '_xbb._srdlmm': 'Vă rugăm să introduceți parola de conectare',
  '_xbb._qrmm': 'Confirmați parola',
  '_xbb._srqrmm': 'Vă rugăm să introduceți parola de confirmare',
  '_xbb._yqm': 'codul de invitație',
  '_xbb._sryqm': 'Introduceți codul de invitație',
  '_xbb._yxyzm': 'Codul de verificare a e-mailului',
  '_xbb._sryzm': 'Introduceți codul de verificare a e-mailului',
  '_xbb._yydbty': 'Am citit și am fost de acord',
  '_xbb._rzxy': 'Acord de reglementare'
}
